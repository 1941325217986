import styled from "styled-components"
import {
    FaUpload,
    FaFileUpload,
    FaTrashAlt,
} from 'react-icons/fa'
import {
    BiDonateHeart,BiSkipNextCircle
} from 'react-icons/bi'


export const FileUploadWrapper = styled.section`
    position: relative; 
    border: 2px dotted lightgray;
    padding: 20px 15%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    max-height: 150px;
  width: 100%;
`
export const WraperList = styled.section`
    margin-top:20px;  
    position: relative; 
    border: 2px dotted lightgray;
    gap: 30px;
    border-radius: 6px;
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    color: #fff;
    width: 100%;
    padding: 10px;
`

export const Card = styled.div`
  position: relative;
  border-radius: 15px 0px 15px 0px;
  text-align: center;
  background-color: #ffffff;
  color: black;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 30%);
  @media screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
  }

  label {
    border-radius: 15px 0px 15px 0px;
    text-align: start;
    padding-left: 5px;
    color: white;
    width: 70%;
    position: relative;
  }

  .img{
    max-width: 200px;
    max-height: 200px;
     border-radius: 0px 0px 15px 0px;
  }
  



`

export const FormField = styled.input`
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
        
    &:focus {
        outline: none;
    }
`

export const InputLabel = styled.label`
  top: -25px;
  font-size: 18px;
  color: white;
  left: 0;
  position: absolute;
`

export const DragDropText = styled.p`
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
  overflow: hidden;
`

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #40a52b;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #40a52b;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: #40a52b;
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  &:hover {
    color: #fff;
    outline: 0;
    background: transparent;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`

export const UploadIcon = styled(FaUpload)`
    font-size: 14px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`

export const UploadedFileIcon = styled(FaFileUpload)`
    font-size: 52px;
    color: grey;
    margin: 15px 0;
`

export const TrashFiles = styled(FaTrashAlt)`
    font-size: 18px; 
    color: grey;
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    cursor: pointer;
`
export const LastDonation = styled(BiDonateHeart)`
`
export const LastDonationWrapper = styled.div`
    color: #40a52b;
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: center;
`

export const Decrease = styled(BiSkipNextCircle)`
    font-size: 35px;
    color: grey;  
    margin: 5px;
    cursor: pointer;
    transform: scaleX(-1);
`

export const Increase = styled(BiSkipNextCircle)`
    font-size: 35px; 
    color: grey; 
    margin: 5px;
    cursor: pointer;
`

export const ContainerPos = styled.div`
    display: flex;
    color: grey;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 5px;
    div {
      border: 2px solid grey;
      border-radius: 5px;
      width: 20px; 
    }
    .disabled{
      visibility: hidden;
    }
`
