import {
    Wrapper,
    StyledInput,
    OptionsWrapper,
    Option
} from './elements'

const Input = ({ type='text', text, width, idValue, values, changeValues, onlyReading = false, onlyNumbers = false, placeholder='', options = null }) => {

    const changeHandler = (id, e) => {
        changeValues(
            {
                ...values,
                [id]: e.target.value
            }
        )
    }

    const checkInput = (e) => {

        if (onlyNumbers){
            var charCode = (e.which) ? e.which : e.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                    e.preventDefault();
        }

    }
    function setTwoNumberDecimal(event) {
        let value = parseFloat(event.target.value).toFixed(2);
        if(!/^\d*(\.\d{0,4})?$/.test(event.target.value)){

            event.target.value =  isNaN(value)? 0:value;
        }

    }
    return (
            type === 'checkbox' || type === 'radio'?
                <Wrapper width={width}>
                    <OptionsWrapper>
                        <h2>{text}</h2>
                        {
                            options.map((element, index) => {
                                return(
                                    <Option key={index}>
                                        <StyledInput onWheel={() => {this.blur()}} type={type} value={element} name={idValue} id={`checkbox-${idValue}-${index}`} onChange={(e) => changeHandler(idValue, e)}/>
                                        <label htmlFor={`checkbox-${idValue}-${index}`}>
                                            {element}
                                        </label>
                                    </Option>
                                )
                            })
                        }
                    </OptionsWrapper>
                </Wrapper>
            :
                <Wrapper width={width}>
                    {
                        text?
                            <p>{ text }</p>
                        :
                            ''
                    }
                    {
                        onlyReading ?
                            <StyledInput onWheel={() => {this !== undefined && this.blur()}}type={type} value={values && values[idValue] ? values[idValue] : ''} onChange={(e) => changeHandler(idValue, e)} placeholder={placeholder} onlyRead/>
                        :
                            onlyNumbers?
                                <StyledInput type={'number'} onInput={setTwoNumberDecimal} min="0" step="0.01" onWheel={() => {this !== undefined && this.blur()}} type={type} value={values && values[idValue] ? values[idValue] : ''} onChange={(e) => changeHandler(idValue, e)} placeholder={placeholder}/>
                                :
                                <StyledInput onWheel={() => {this !== undefined && this.blur()}} type={type} value={values && values[idValue] ? values[idValue] : ''} onChange={(e) => changeHandler(idValue, e)} placeholder={placeholder}/>

                    }
                </Wrapper>
    )
}

export default Input
