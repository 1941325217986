import styled from "styled-components";
import { Link as LinkRouter} from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px 4px rgb(0 0 0 / 30%);
  color: white;
  background-color: #40a52b;
  border-radius: 8px;
  padding: 25px;
  width: 80%;
  gap:50px;
  h1 {
    color:white;
    font-size:3em ;
    font-weight: bold;
  }
  p{
    font-size: 1.5em;
    text-align: center;
  } 
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;    
`;

export const LinkStyled=  styled(LinkRouter)`
    padding: 5px 30px;
    color:#45a731;
    text-decoration: none; 
    background-color:#e7e4e4;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover{
        background-color: #606060;
        color:#e7e4e4;
    }
    font-weight: 600;
    border-radius: 10px;
    margin: 5px 1rem 5px 5px;
    display: flex;
    align-items: center;
    min-width: 70px;
    gap: 3px;
`
