import styled from "styled-components";

export const PrivacyContainer = styled.div`
    background: #e9ecef;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin: 30px 30px;
    padding: 20px;
    font-size: 1.5em;
    line-height: 40px;

    gap: 20px;
    background: #40a52b;
    color: #FFF;
    
    a{
        color: #FFF;
        font-weight: bold;
        font-style: italic;
    }

    p{
        margin: auto 1px;
    }

    .title{
        color: white;
        font-size: 1.8em;
        word-break : break-word;
    }
`