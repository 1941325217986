import styled from 'styled-components'

export const ImageTestWrapper = styled.div`
    width: 100%; 
    border-radius: 15px 15px 0 0;
    height: 220px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 

    img{
        width: 100%;
        height: 100%;
        transition: 1s;
        position: absolute;
        display: block;
        top:0;
        max-height: 250px;
        border-radius: 15px 15px 0 0;
    }

    .notInImg{
        display: none;
    }

    .presentImg{
        opacity: 1;
        visibility: visible; 
    }

    .previousImg{
        opacity: 0;
        visibility: hidden; 
    }

    .nextImg{
        opacity: 0;
        visibility: hidden; 
    }

    .previous, .next{
        position: absolute;
        top: calc(50% - 50px);
        margin: 0 50px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-top: solid 5px #4BAA31;
        border-right: solid 5px #4BAA31;
        height: 30px;
        width: 30px;
        opacity: 0.75;

        @media screen and (max-width: 1020px){
            top: calc(30vh - 64px);
            height: 20px;
            width: 20px;
            margin: 0 25px;
        }
    }

    .previous{
        left: 0;
        transform: rotate(-135deg);
    }

    .next{
        right: 0;
        transform: rotate(45deg);
    }
`

export const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    button{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 2px white;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        opacity: 0.75;
    }

    .selected{
        background-color: white;
    }
`
