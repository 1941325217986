import {useContext, useEffect, useState} from 'react'
import getDonations from '../Services/GetDonaciones'
import DonationsContext from '../contexts/DonationsContext'

const INITIAL_PAGE = 0

export function useDonations ({ location, date } = { location: null }) {
    const [loading, setLoading] = useState(false)
    const [loadingNextPage, setLoadingNextPage] = useState(false)

    const [page, setPage] = useState(INITIAL_PAGE)
    const [lastPage, setLastPage] = useState(false)
    const [reload, setReload] = useState(false)
    const {donations, setDonations} = useContext(DonationsContext)

    // recuperamos la ubicacion del localStorage
    const loationToUse = location || localStorage.getItem('lastlocation') || 'Buenos Aires'

    useEffect(function () {
        setLoading(true);
        setPage(0);
        getDonations({ page:0,location: loationToUse, date })
            .then(donationsRes => {
                setDonations(donationsRes)
                setLoading(false)
                // guardamos la ubicacion en el localStorage
                localStorage.setItem('lastlocation', location)
            })
    }, [location, date])

    useEffect(function () {
        if (page === INITIAL_PAGE) return
        if(setLastPage)
        setLoadingNextPage(true)
        getDonations({ location: loationToUse, page, date })
            .then(nextDonations => {
                if(nextDonations.length===0){
                    setLastPage(true);
                }
                setDonations(prevDonations => prevDonations.concat(nextDonations))
                setLoadingNextPage(false)
            })
    }, [loationToUse,reload, page])

    return {loading, loadingNextPage,setDonations, donations, setPage}
}
