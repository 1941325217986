import styled from "styled-components";

export const TermsContainer = styled.div`
    background: #40a52b;
    color: #FFF;
    border-radius: 20px;
    margin: 30px 30px;
    padding: 20px;
    font-size: 1.5em;
    display: flex;
    gap: 20px;
    flex-direction: column;
    line-height: 40px;

    ol {
        list-style-position: inside;
    }

    a{
        color: #FFF;
        font-weight: bold;
        font-style: italic;
        word-break: break-word;
    }

    h1{
        color:white;
        font-size: 1.8em;
    }
`