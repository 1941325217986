//Styled Components
import {
  StyledHeartContainer
} from './elements'

const HeartContainer = ({ style = null, noText = false, children}) => {

  return (
    <StyledHeartContainer childStyle={style} noText={noText}>
      <img src={`${process.env.PUBLIC_URL}/imagenes/halfHeart.png`} alt='corazon'/>
      {children}
    </StyledHeartContainer>
  )
}

export default HeartContainer
