import styled from "styled-components"

export const TarjetaWrapper = styled.div`
    
    position: absolute;
    transition: 1s;
    width: 70%;
    height: fit-content;
    background-color: #46b926;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-left: 15%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: #FFFFFF;

    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      height: inherit;
    }

`
export const LoadingWrapper = styled.div` 
    background-color: white;
    padding:20px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 

`
export const ImageSection = styled.div`

    
    
    padding: 40px;
    width: 40%;
    margin: auto;
    .banner{
      box-shadow: 0px 0px 5px 4px rgb(0 0 0 / 30%);
      background-image: ${props => props.src? 'url("'+props.src+'")' : 'white'};
      border: 3px solid white;
    }
    img{
        box-shadow: 0px 0px 5px 4px rgb(0 0 0 / 30%);
        transform: rotate(4deg);
        position: relative;
        justify-content: center;
        align-items: center;
        max-width:100%;
        max-height:100%;
        border: 3px solid white; 
    }

    @media screen and (max-width: 768px){
      width: 90%;
      padding: 5px;
      padding-top: 20px;
    }
`


export const TextSection = styled.div` 
  padding: 30px 20px 30px 0px;
  width: 50%;
  display: flex;
  text-align: justify;
  flex-direction: column;
  gap: 15px;
  h2{
    color: white;
  }

  p{
    font-size: 1.2em;
  }

  .teaming{
    font-family: 'teaming';
  }

  @media screen and (max-width: 768px){
      margin: 0 auto;
      padding: 5px;
      text-align: center;
      width: 100%;
    }

`
