import {API_URL} from "../Settings/ApiSettings";

const {API_KEY, url} = require('./constantes');

function formDataConst(donacion) {
    //Creamos el form data
    const formData = new FormData()
    formData.append('title', donacion.title)
    let x = 0;
    donacion.imagenes.forEach(file =>{
        let multimetadata = {
            id : file.id? file.id : null,
            tipo:'imagen',
            miniatura:file.tipo==='miniatura',
            active:true
        };
        if(file.url !== undefined){
            multimetadata.url = file.url;
            formData.append('imagenes', JSON.stringify(multimetadata));
            return;
        }
        formData.append('files', file.file)
        multimetadata.posFile =x;
        multimetadata.nombre =file.file.name;
        formData.append('imagenes', JSON.stringify(multimetadata));
        x++;
    })
    if(donacion.video === 'si'){
        let multimetadata = {
            url:donacion.link_video,
            tipo:'video',
            miniatura:false,
            active:true
        };
        formData.append('imagenes', JSON.stringify(multimetadata));
    }
    formData.append('institucion', donacion.institucion)
    formData.append('resumen', donacion.resumen)
    formData.append('text', donacion.text)
    formData.append('date', donacion.date)
    formData.append('amount', donacion.amount)
    formData.append('location', donacion.location)
    formData.append('state', donacion.state)
    formData.append('video', donacion.video)
    formData.append('linkvideo', donacion.link_video)
    return formData;
}
async function create(donacion,token, callBack) {
    try{
        //Creamos el form data
        const formData =  formDataConst(donacion);
        // send `POST` request
        await fetch(`${url}/donaciones/create`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: formData,
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.status==='SUCCESS'?json.data:json.message))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function deleteDonacion(id,token, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/donaciones/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function deleteVideo(id,token, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/multimedia/delete/video/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function deleteImage(id,token, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/multimedia/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.status==='SUCCESS'?json.data:json.message))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function editDonacion(donacion,token, callBack) {
    try{
        //Creamos el form data
        const formData =  formDataConst(donacion);
        // send `DELETE` request
        await fetch(`${url}/donaciones/update/${donacion.id}`, {
            method: 'PUT',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: formData,
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.status==='SUCCESS'?json.data:json.message))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function getAll(callBack) {
    try{
        // send `POST` request
        await fetch(`${url}/donaciones/list`, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer '+ API_KEY
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function getById(id,callBack) {
    try{
        // send `POST` request

        fetch(`${API_URL}/donaciones/${id}`, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then( (result) =>{
                if(result.status === 'SUCCESS')
                    callBack(result);
                else
                    callBack(null);
            })
            .catch((error)=> console.log('Algo fallo en la conexion a la base de datos: '+ error.message))

    }catch (e){
        console.log(e);
    }
}


// send `POST` request
const DonacionService = {
    create,
    deleteDonacion,
    deleteVideo,
    deleteImage,
    getAll,
    getById,
    editDonacion
};


export default DonacionService
