import styled from 'styled-components'

import {
    FiArrowLeftCircle
} from 'react-icons/fi'

import {
    AiOutlineCloseCircle
} from 'react-icons/ai'

export const WrapperCMS = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 18px;
    transition: all 500ms ease;

    @media screen and (max-width: 1260px){
        margin-top: 84px;
    }

    h1{
        font-size: 2em;
        margin-top: 25px;
        color: white;
    }

    .loader{
        margin-top: 150px;
    }
`

export const WrapperManager = styled.div`

    width: 90%;
    margin: 25px auto;
    color: #2eaf21; 
    height: min-content;
    background-color: #e9e9e999;
    padding: 15px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    font-size: 18px;
    transition: all 500ms ease;
 
    h1{
        font-size: 2em;
        margin-top: 25px;
        color: #2eaf21;
    }
  
    .loader{
        margin-top: 150px;
    }
    h2,label,textarea, input, select{
      color: #2eaf21;
    }
  .submit-btn{

    position: relative;
    transition: color 250ms ease-in-out;
    z-index: 1;
    box-sizing: border-box; 
    background-color: white; 
    cursor: pointer; 
    padding: 1.1em 2.8em;   
    color: #40a52b; 
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: #40a52b;
      z-index: -1;
      transition: width 250ms ease-in-out;
    }

    &:hover {
      color: #fff;
      outline: 0;
      background: transparent;

      &:after {
        border-radius: 15px;
        width: 110%;
      }
    }

    &:focus {
      outline: 0;
      background: transparent;
    }

    &:disabled {
      opacity: 0.4;
      filter: grayscale(100%);
      pointer-events: none;
    }
  }
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around; 
  input, select{
    width: 100%;
    border: none;
    border-bottom: 2px solid;
    border-color: #40a52b;
    border-radius: 5px;
    outline: none;
    height: 40px;
    width: 50%;
    padding: 5px;
    font-size: 18px;
    color: #2eaf21;
  }
  .react-datepicker-wrapper{
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
    outline: none;   
  } 
  .react-datepicker-popper{
    z-index: 2;
  }

`
export const FormWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
export const Textarea = styled.div`
    width: 100%;
    margin: 15px 0;
    
    textarea{
        border: 2px white solid;
        border-radius: 10px;
        resize: none;
        outline: none;
        padding: 10px;
        width: 100%;
        height: 150px;
    } 
`

export const Select = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 10px 0;
    gap: 5px;
     
`


export const ManagerWrapper = styled.div`
    width: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FileWrapper = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Volver = styled(FiArrowLeftCircle)`
    font-size: 3rem;
  color: #2eaf21;
  cursor: pointer;

    :hover{
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.6));
    }
`

export const Close = styled(AiOutlineCloseCircle)`
    font-size: 3rem;
    color: black;
    cursor: pointer;
    position: fixed;
    z-index: 22;

    :hover{
        filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.6));
    }
`

export const FixedWrapper = styled.div`
    position: absolute;
    top: 25px;
    right: 75px;
`


export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 6;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`

export const WrapperPreview = styled.div`
    width: 90%;
    height: 80%;
    background-color: #FFF;
    margin-top: 90px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    position: relative;

    h1{
        font-size: 24px;
        color: #2eaf21;
        margin: 15px 0 30px 0;
    }
`
