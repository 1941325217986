//React
import React from 'react'

//Styled Components
import { 
  AboutWarpped 
} from './elements'

//Components
import Section from '../../components/Section'
import HeartContainer from '../../components/HeartContainer'

const AboutUs = () => {
  const style = `
    
    h1{
      
      
      color:white;
      font-size: 1.8em;
    }

    h3{
      font-weight: 100;
      margin-bottom: 10px;
      color:white;
      font-family: teaming;
    }

    p{
      font-size: 1.5em;
      line-height: 40px;
    }

    & .text-item{
      margin-top: 15px;
    }
    & .text-item i{
      font-weight:bold;
      font-size: 1.5em;
    }
  `

  return (
    <Section>
      <HeartContainer style={style}>
      <AboutWarpped>

        <h1>Acerca de Teaming</h1>
        <h3>TEAMING. Unimos a muchos por muy poco.</h3>
        <div className='text-item'>
          <i>¿Qué es Teaming?</i>
          <p>Teaming es una práctica internacional basada en donaciones que nació en el año 1998 y tiene presencia en más de 40 países. Su objetivo es formar equipos y canalizar la ayuda, creando el concepto de cooperación en sus prácticas. Se enfoca en las necesidades indispensables para poder llegar a aquellas instituciones que menos recursos tienen.
          </p>
        </div>
        <div className='text-item'>
          <i>¿En qué consiste?</i>
          <p>Teaming es ayudar, es estar y acompañar a quienes más lo necesitan. Y muchos colaboradores de nuestra compañía quieren ser parte de este gran propósito. Es así que nuestr@s Teamers realizan mensualmente una donación voluntaria de un monto determinado de sus sueldos y CAT Technologies suma esfuerzos duplicando dichos montos.</p>
          <p>Nuestr@s Teamers pueden sugerir instituciones y también participar de cada experiencia solidaria.</p>
          <p>Gracias al aporte de nuestr@s Teamers y al de nuestra organización, podemos realizar mensualmente donaciones a instituciones que lo necesitan.<br/>Posteriormente a cada gran acción solidaria, compartimos lo que fue la experiencia a través de nuestras redes sociales y de este sitio web.</p>
<p><i>¡Ser parte de Teaming nos llena de orgullo y fortalece nuestro compromiso solidario!</i></p>
        </div>
        </AboutWarpped>
      </HeartContainer>
      
    </Section>
  )
}

export default AboutUs
