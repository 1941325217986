//React
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import { useEffect } from 'react'
//Global Style
import GlobalStyle from './GlobalStyle'

//Components
import NavbarNew from './components/NavbarNew'
import Footer from './components/Footer'
import Modal from './components/Modal'
import DonacionTarjeta from './components/DonacionTarjeta'

//Pages
import Home from './pages/Home'
import DonationsPage from './pages/DonationsPage'
import AboutUs from './pages/AboutUs'
import Suggest from './pages/Suggest'
import JoinUs from './pages/JoinUs'
import Terms from './pages/Terms'
import Help from './pages/Help'
import Privacy from './pages/Privacy'
import Donation from './pages/Donation'
import Login from './pages/Login'
import CMS from './pages/CMS'

//Contexts
import {DonationContextProvider} from "./contexts/DonationsContext";
import { UserContextProvider } from './contexts/UserContext'
import AltaDonacion from "./pages/AltaDonacion";

function App() {
 
  return (
    <BrowserRouter className='Body' basename='/web-teaming'>
      <UserContextProvider>
        <GlobalStyle/>
        <NavbarNew/>
        <Modal/>
        <Routes>
            <Route path='/DonationsPage' element={
                <DonationContextProvider>
                    <DonationsPage/>
                </DonationContextProvider>}/>
            <Route path='/about' element={<AboutUs/>}/>
            <Route path='/sugeri' element={<Suggest/>}/>
            <Route path='/joinUs' element={<JoinUs/>}/>
            <Route path='/terms' element={<Terms/>}/>
            <Route path='/help' element={<Help/>}/>
            <Route path='/privacy' element={<Privacy/>}/>
            <Route path='/donation/:id' element={
              <DonationContextProvider>
                <Donation/>
              </DonationContextProvider>
            }/>
            <Route path='/lastDonation' element={<DonacionTarjeta/>}/>
            <Route path="/admin">
                <Route path={'cms/*'} element={ <CMS/> } />
                <Route index path={"*"} element={ <Login/> } />
           </Route>
            <Route path={'*'} element={<Home/>}/>
        </Routes>
        <Footer/>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
