//React Imports
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

//Styled Components
import {
    LoginPage,
    LoginWrapper,
    LogoWrapper,
    FormWrapper,
    Input,
    IconUser,
    IconPassword,
    SubmitWrapper,LoadingWrapper
} from './elements'

//Components
import Logo from "../../components/Logo"
import Button from '../../components/Button'

//Context
import { useUserContext } from '../../contexts/UserContext'
import Loading from '../../components/Loading'


const Login = () => {
    const [loading, setLoading] = useState(false)
    const { user, logged, triedLog, setTriedLog, handleUser, logIn } = useUserContext()
    const navigate = useNavigate()

    const handleLogIn = e => {
        setTriedLog(false)
        setLoading(!loading)
        logIn(e)
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if(logged)
            navigate('/admin/cms')
    }, [logged])

    return (
        <LoginPage>
                <LoginWrapper>
                    <LogoWrapper>
                        <Logo backgroundColor={'transparent'} imgroute={'/imagenes/Teaming_blanco-03.png'} />
                        <Logo backgroundColor={'transparent'} imgroute={'/imagenes/NuevoLogoCAT_Negativo.png'} />
                    </LogoWrapper>
                    <FormWrapper onChange={handleUser}>
                        <div>
                            <Input>
                                <input type={'text'} name={'username'} placeholder={'Usuario'}/>
                                <IconUser/>
                            </Input>
                            <Input>
                                <input type={'password'} name={'password'} placeholder={'Contraseña'}/>
                                <IconPassword/>
                            </Input>
                        </div>
                        {
                            !logged && triedLog?
                                <span >Usuario y/o contraseña incorrectos</span>
                                :
                                ''
                        }
                        <SubmitWrapper className={loading ? 'center' : 'end'}>
                            {
                                loading?
                                    <LoadingWrapper>
                                        <Loading iconSize={'35px'}/>
                                    </LoadingWrapper>
                                    :
                                    <Button size={'small'} color={"white"} text={'Ingresar'} onClickHandle={handleLogIn}/>

                            }
                        </SubmitWrapper>
                    </FormWrapper>
                </LoginWrapper>
        </LoginPage>
    )
}

export default Login
