//Styled Components
import { 
    AddForm,
    FormItem,
    FormItemCheck,
    FormItemRadio,
    StyledLink,
    FormButtonWrapper
} from './elements';

//components
import Button from '../Button';
import Loading from "../Loading";

export const Form = ({formData, component, handleChange, submitButtonText, submitButtonAction, backTo,loading = false,children}) => {

    return(
        <AddForm onChange={handleChange}>
            {
                component
                ?
                    component.map((formComponent, index) => {

                        switch(formComponent.input) {
                            case 'text':
                                return(
                                    <FormItem display={(formComponent.hidden)? formComponent.hidden : 'block'}>
                                        <p>{formComponent.title}{formComponent.obligatory? "*" : ""}:</p>
                                        <input key={index} type={formComponent.input} id={formComponent.id} name={formComponent.id} defaultValue={formData[formComponent.id] && formData[formComponent.id] !== ''? formData[formComponent.id] : ""}/>
                                    </FormItem>
                                )
                            case 'text_hidden':
                                return(
                                    <FormItem id={formComponent.id}>
                                        <p>{formComponent.title}:</p>
                                        <input key={index} type={formComponent.input} disabled={true} id={formComponent.id} name={formComponent.id} value={formData[formComponent.id] && formData[formComponent.id] !== ''? formData[formComponent.id] : ""} onChange={e => this.onTodoChange(e.target.value)}/>
                                    </FormItem>
                                )
                            case 'textarea':
                                return(
                                    <FormItem>
                                        <p>{formComponent.title}:</p>
                                        <textarea key={index} type={formComponent.input} id={formComponent.id} rows={4} name={formComponent.id} defaultValue={formData[formComponent.id] && formData[formComponent.id] !== ''? formData[formComponent.id] : ""}/>
                                    </FormItem>
                                )
                            case 'select':
                                return(
                                    <FormItem>
                                        <p>{formComponent.title}:</p>
                                        <select key={index} id={formComponent.id} name={formComponent.id}>
                                            <option value={null}></option>
                                            {
                                                formComponent.options.map(option => (
                                                    <option key={option.id} value={option.id} selected={formData[formComponent.id] && formData[formComponent.id] === option.id? true : false}>{option.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    </FormItem>
                                )
                            case 'checkbox':
                                return(
                                    <FormItem>
                                        <label>
                                            <input key={index} type={formComponent.input} id={formComponent.id} name={formComponent.id} defaultChecked={formData[formComponent.id] && formData[formComponent.id] === true? true : false}/>&nbsp;
                                            {formComponent.title}{formComponent.obligatory? "*" : ""}
                                        </label>
                                    </FormItem>
                                )
                            case 'checkbox-multi':
                                return(
                                    <FormItem>
                                        <p>{formComponent.title}:</p>
                                        <div className={formComponent.options.length >4 ?'radio_container_box' :'radio_container'}>
                                            {
                                                formComponent.options.map((option,index) => (
                                                    <p>
                                                        <input key={index} type={'checkbox'} value={option.id} id={formComponent.id} name={formComponent.id} defaultChecked={formData[formComponent.id] && formData[formComponent.id].findIndex(elem => elem === option.id) !== -1}/>
                                                        &nbsp;
                                                        <label htmlFor={option.id}>{option.value}</label>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </FormItem>
                                )
                            case 'radio-group':
                                return(
                                    <FormItem>
                                        <p>{formComponent.title}:</p>
                                        <div className={'radio_container'}>
                                            {
                                                formComponent.options.map((option,index) => (
                                                    <p>
                                                        <input key={index} type={'radio'} value={option.id} id={formComponent.id} name={formComponent.id} defaultChecked={formData[formComponent.id] && formData[formComponent.id] === option.id? true : false}/>
                                                        &nbsp;
                                                        <label>{option.value}</label>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </FormItem>
                                )
                            //case 'file':
                            //    return(
                            //        <FormItem>
                            //            <p>{formComponent.title}:</p>
                            //            <input key={index} type={formComponent.input} id={formComponent.id} name={formComponent.id} accept={formComponent.accept}/>
                            //        </FormItem>
                            //    );
                            //    break;
                            default:
                                return(
                                    <>
                                    </>
                                )
                        }

                    })
                :
                   ""
            }
            {children}
            <FormButtonWrapper>
                {
                    backTo?
                        <StyledLink to={backTo}>
                            <Button size={'small'} color={'red'} hover={'red'}>
                                Atras
                            </Button>
                        </StyledLink>
                        :
                        <></>
                }
                {
                    loading?
                        <Button size={'small'}>
                            <Loading color={"white"}/>
                        </Button>
                        :
                        submitButtonText?
                            <Button size={'small'} onClickHandle={submitButtonAction}>
                                {submitButtonText}
                            </Button>
                            :
                            <></>
                }
            </FormButtonWrapper>
        </AddForm>
    )

}

export default Form
