
const { url} = require('./constantes');

async function login(user,callBack) {
    try{
        // send `POST` request
        await fetch(`${url}/login/auth`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '*'
            },
            body: JSON.stringify(user),
        })
            .then(res => {
                return res.json()
            })
            .then(callBack)
            .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}


// send `POST` request
const AuthService = {
    login,
};


export default AuthService
