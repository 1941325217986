//React
import { useState, useEffect } from 'react'


//Styled Components
import {
  CarrouselWrapper,
  ButtonWrapper,
  SliderWrapper,
  Wrapper
} from './elements'
import DonacionTarjeta from "../DonacionTarjeta";


//components
import Button from '../Button';
import Loading from '../Loading';

//react
import { FaPause,FaPlay,FaAngleDoubleLeft,FaAngleDoubleRight, FaPauseCircle } from 'react-icons/fa';

//Services
const { url } = require('../../Services/constantes');

const Carrouseldetalle = ({ downButtons = false, images, slider, sliderPageSize }) => {
  const [index, setIndex] = useState(0)
  const [currPage, setCurrPage] = useState(0)
  const [play, setPlay] = useState(true);

  const selectNewImage = (next = true) => {

      const condition = next ? index < images.length - 1 : index > 0
      const nextIndex = next ? (condition ? index + 1 : 0) : (condition ? index - 1 : images.length - 1)

      setIndex(nextIndex)
  }

  const selectClass = (pos) => {
    if(pos === index)
      return 'presentImg'
    if(index === 0 && pos === images.length - 1)
      return 'previousImg'
    if(index === images.length - 1 && pos === 0)
      return 'nextImg'
    if(pos === index - 1)
      return 'previousImg'
    if(pos === index + 1)
      return 'nextImg'
    return 'notInImg'
  }

  const selectImageFromSlider = (pos) => {
    setIndex(pos)
  }

  const changeGallery = (shift) => {

    setCurrPage(currPage + shift);
  }

  const toggle = () =>{
    setPlay(!play);
  }

  useEffect(() => {
    if(play){
      const interval = setInterval(() => {
        selectNewImage()
      }, 5000)
  
      return () => {
        clearInterval(interval);
      };
    }
  });

  return (
    <Wrapper>
      <CarrouselWrapper>
        {
          images.map((value, index) => {

            return(
              value.tipo==='lastDonation'?
                <DonacionTarjeta name='carrousel-img' className={selectClass(index)} />
              :
                value.url.includes('youtube')?
                  <img name={'carrousel-img'} key={index} className={selectClass(index)} src={`${value.url}`} alt={'img'}/>
                :
                <img name={'carrousel-img'} key={index} className={selectClass(index)} src={`${url.slice(0,-4)}${value.url}`} alt={'img'}/>
            )
          })
        }
        <div className={'previous'} onClick={() => selectNewImage(false)}/>
        <div className={'next'} onClick={selectNewImage}/>

        <div className={'action'}>
          <Button size={'small'} onClickHandle={() => toggle()}>
            {
              play?
              <FaPause/>
              :
              <FaPlay/>
            }
            
          </Button>
        </div>

        {
          downButtons ?
            <ButtonWrapper>
              {
                images.map((value, indexArray) => {
                  return(
                    <button key={value + indexArray} className={indexArray === index ? 'selected' : ''} onClick={() => setIndex(indexArray)}></button>
                  )
                })
              }
            </ButtonWrapper>
          :
            ''
        }
        
      </CarrouselWrapper>
      {
            slider && slider.length > 0 && currPage >= 0?
                <SliderWrapper>
                    <Button size={'small'} disabled={currPage === 0}  onClickHandle={() => changeGallery(-1)}>
                        <FaAngleDoubleLeft/>
                    </Button>
                    {
                        slider[currPage].images.map((img,index) => {
                            return (
                              
                              img.url.includes('youtube')?
                                <img name={'carrousel-img'} key={index}  className={'img_thumb'} onClick={() => selectImageFromSlider((currPage*sliderPageSize)+index)} src={`${img.url}`} alt={'img'} />
                              :
                              <img name={'carrousel-img'} key={index}  className={'img_thumb'} onClick={() => selectImageFromSlider((currPage*sliderPageSize)+index)} src={`${url.slice(0,-4)}${img.url}`} alt={'img'}/>
                            )
                        })
                    }
                    <Button size={'small'} disabled={currPage === (slider.length -1)} onClickHandle={() => changeGallery(1)}>
                        <FaAngleDoubleRight/>
                    </Button>
                </SliderWrapper>
            :
            <div>
                <Loading/>
            </div>
        }
    </Wrapper>
  )
}

export default Carrouseldetalle
