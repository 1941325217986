//React
import React from 'react'

//Styled components
import {
    Nav,
    NavItem,
    Navlinks,
    NavMenu
} from './elements'

//Contexts
import useWindowDimensions from "../../contexts/windowDimensions";

const Footer = () => {
    const {width} = useWindowDimensions()

    return (
        <>
          <Nav id={'footer'}>
                <NavMenu>
                    {
                        width < 490 ?
                            <NavMenu>
                                <NavItem>
                                    <Navlinks to='/terms'>Términos y condiciones de uso</Navlinks>
                                </NavItem>
                                <NavItem>
                                    <Navlinks to='/privacy'>Políticas de privacidad</Navlinks>
                                </NavItem>
                                <NavItem>
                                    <Navlinks to='/help'>Ayuda</Navlinks>
                                </NavItem>
                            </NavMenu>
                            :
                            <>
                                <NavItem>
                                    <Navlinks to='/terms'>Términos y condiciones de uso</Navlinks>
                                </NavItem>
                                <strong>|</strong>
                                <NavItem>
                                    <Navlinks to='/help'>Ayuda</Navlinks>
                                </NavItem>
                                <strong>|</strong>
                                <NavItem>
                                    <Navlinks to='/privacy'>Políticas de privacidad</Navlinks>
                                </NavItem>
                            </>
                    }

                </NavMenu>
          </Nav>
        </>
    )
}

export default Footer
