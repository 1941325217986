//React
import React from 'react'
import {FaTimes} from 'react-icons/fa'

//Styled Components
import { 
    BotonCerrar,
    ContenedorModal, 
    Overlay 
} from './elements'


const Modal = ({ children, estado, setEstado }) => {
    return(
        <>
            {/*si el estado es verdadero muesta el contenido del modal*/}
            {estado && 
                <Overlay>
                    <ContenedorModal>
                        <BotonCerrar onClick={()=>setEstado(!estado)}>
                            <FaTimes/>
                        </BotonCerrar>
                        {children}
                    </ContenedorModal>
                </Overlay>
            }
        </>
    )
}

export default Modal