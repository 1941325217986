//React

import React, {useCallback, useState, useContext, useEffect} from 'react';

//Styled Elements
import {
  Card,
    DonationsWrapper,
  Navlinks,Trash,Edit,Wrapper
} from './elements';
import DonationCard from "../DonationCard";
import ConfirmationPopUp from "../ConfirmationPopUp";

import DonationsContext from "../../contexts/DonationsContext";
import {useNavigate} from "react-router-dom";
import Button from "../Button";
import Loading from "../Loading";

//Listado de donaciones segun localidad de donaciones
const Donations = (({setDonations, adminMode=false,ListaDonaciones }) => {
  const { deleteDonation,donationsDeleting } = useContext(DonationsContext);
  const [deleting,setDeleting] = useState({state:false,id:null});
  const navigate = useNavigate();
  useEffect(()=>{
      if(!donationsDeleting){
          ListaDonaciones = ListaDonaciones.filter(don => don.id!==deleting.id);
          setDonations(ListaDonaciones);
          setDeleting({state:false,id:null});
      }
  },[donationsDeleting])
  return (
        <DonationsWrapper>
          <ConfirmationPopUp show={deleting.state} title={'Eliminar Donación'} content={'Esta seguro que desea eliminar esta donación'} closeHander={() => setDeleting({state:false,id:null})}>
              <Button color={'secondary'} id={deleting.id} size={'small'} color={'#6CC788'} hover={'#5ac079'} onClickHandle={()=>deleteDonation(deleting.id)}>
                  {donationsDeleting? <Loading/>:'Confirmar'}
              </Button>
              <Button color={'secondary'} size={'small'} color={'white'} hover={'#b30000'} onClickHandle={() => setDeleting({state:false,id:null})}>
                  Cancelar
              </Button>
          </ConfirmationPopUp>
        {//Carga las donaciones y las muestra.
          ListaDonaciones.map((donation,i) => {
            const { id } = donation;
            return (
              <Card key={i}>

                {
                  adminMode?
                      <Wrapper>
                        <Edit className={'left-icon'} onClick={(e)=>navigate(`/admin/cms/donacion/${id}`)}/>
                        <Trash  onClick={()=>setDeleting({state:true,id:id})}/>
                      </Wrapper>

                      :
                      <></>
                }
                <Navlinks key={i} to={`/Donation/${id}`}>
                  <DonationCard adminMode={true} donation={donation}/>
                </Navlinks>
              </Card>
            )
          })
        }
      </DonationsWrapper>
  )
})

export default Donations;
