//Styled Components
import { TermsContainer } from './elements'

const Terms = () => {
  return (
    <TermsContainer>        
        <h1>Términos y condiciones de uso.</h1>
        <p>Al navegar la página, ser seguidor, publicar contenido y/o comentarios estarás aceptando nuestros términos y condiciones, así como también los de Facebook <a href='https://www.facebook.com/terms.php' target="_blank">https://www.facebook.com/terms.php</a></p>
        <p>El contenido publicado por otros usuarios en esta página no refleja, necesariamente, los puntos de vista del Grupo CAT Technologies (Cat Technologies Argentina S.A., CAT Technologies Customer Experiences S.A. y Adval S.A.) (“CAT Technologies”) así como, tampoco garantiza la exactitud y/o veracidad de dichos contenidos.</p>

        <p>Los comentarios y opiniones que usted y otros usuarios de esta página posteen están sujetas a la evaluación de CAT Technologies, con el fin de mantener la propuesta de la página y debates que sean pertinentes y respetuosos para con toda esta comunidad. Por lo tanto, CAT Technologies se reserva el derecho a bloquear en cualquier momento a cualquier usuario que no cumpla estas reglas. Aquellos comentarios que no respeten alguna de las siguientes reglas serán eliminados en la oportunidad que CAT Technologies determine luego de analizar el caso puntual:</p>

        <ol>
            <li>Los comentarios que difamen, amenacen o calumnien a CAT Technologies o a cualquier otra persona o grupo de personas;</li>
            <li>Comentarios con groserías que puedan resultar hirientes y/u ofensivos hacia otra persona o grupos de personas;</li>
            <li>Opiniones y comentarios prejuiciosos;</li>
            <li>Comentarios de carácter político o religioso; o que pudieren afectar la identidad de género o autopercibida y/o la vida privada de las personas o grupo de personas;</li>
            <li>Contenido inadecuado para menores de edad, pornográfico, obsceno, indecente, o ilegal;</li>
            <li>Contenido engañoso o perjudicial para los usuarios de la página;</li>
            <li>Contenido no relacionado con el tema de la página (anuncios y clasificados de productos, comentarios aleatorios y descontextualizados, etc.);</li>
            <li>Los archivos dañados, enlaces sospechosos, malware, software dañino, virus, entre otros;</li>
            <li>Los materiales protegidos por derechos de autor que no son propiedad de quien los envió o de CAT Technologies.</li>
        </ol>

        <p>Esta página podrá ofrecer enlaces a sitios web de terceros. CAT Technologies no se responsabiliza por las políticas de privacidad, seguridad y contenidos de estos sitios, no pudiendo ser responsable por eventuales reclamos, pérdidas o daños sufridos por la utilización de esos enlaces. Recomendamos que usted lea las condiciones de uso y política de privacidad de cualquier sitio antes de comenzar a navegar.</p>

        <p>En caso que observes lo que consideres una violación a estos Términos y Condiciones CAT Technologies te solicita que denuncies este hecho enviando un correo electrónico para informarlo y analizar su contenido a <a href="mailto:info@cat-technologies.com">info@cat-technologies.com</a></p>

        <p><i>Estos Términos y Condiciones fueron actualizados por última vez el 24/08/2022.</i></p> 
    </TermsContainer>
  )
}

export default Terms