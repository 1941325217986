//Styled Components
import {
  PrivacyContainer
} from './elements'

const Privacy = () => {
  return (
    <PrivacyContainer>
      <h1 className="title">Políticas de Privacidad</h1>
      <p>LEE ESTO CON DETENIMIENTO. ESTE DOCUMENTO INDICA CÓMO <a href="https://teaming.cat-technologies.com/" target={'_blank'}>https://teaming.cat-technologies.com/</a> en adelante “CAT Technologies”, UTILIZARÁ Y PROTEGERÁ TUS DATOS PERSONALES.</p>
      <p className="title"><strong>Seguridad y Protección de tus Datos Personales</strong></p>
      <p>La seguridad de los datos personales es una prioridad para “CAT Technologies”. Nos esforzamos por ofrecer el más alto nivel de seguridad. Nos encontramos alineados con la Ley Nacional de Protección de Datos Personales, N° 25.326 y sus normas complementarias.</p>
      <p className="title"><strong>Privacidad</strong></p>
      <p>“CAT Technologies” respeta tu privacidad. Toda la información que nos proporciones se tratará con sumo cuidado y diligencia, y solo se utilizará de acuerdo con los límites establecidos en el presente documento.</p>
      <p className="title"><strong>Cómo se utiliza tu información</strong></p>
      <p>“CAT Technologies” utiliza la información que nos brindás para: administrar, gestionar y concretar la donación a las instituciones. “CAT Technologies” tambien utiliza la información que nos brindas para personalizar y mejorar nuestros servicios y para fines estadísticos de la compañía.</p>
      <p className="title"><strong>Acceso a la información</strong></p>
      <p>“CAT Technologies” siempre está comprometido a presentar nuevas soluciones que mejoren el valor de sus productos y servicios. La información no identificable y estadística también podrá ser compartida con socios comerciales. A excepción de los casos anteriores, no compartirá información que podría identificar personalmente a los participantes del programa.</p>
      <p><em>Al ingresar al programa, estás prestando el consentimiento para utilizarlos de acuerdo con esta Política de Privacidad.</em></p>
      <p className="title"><strong>Errores / Omisiones</strong></p>
      <p>La información y material contenido en este Sitio Web han sido verificados para que sean exactos; sin embargo, dicha información y material son suministrados sin ninguna garantía expresa o implícita. “CAT Technologies” no asume ninguna responsabilidad por cualquier error u omisión en dicha información y material.</p>
      <p className="title"><strong>Modificaciones de nuestras Políticas de Privacidad</strong></p>
      <p>“CAT Technologies” se reserva el derecho, a su exclusiva discreción, de modificar, alterar, agregar o eliminar parte de estas Políticas de privacidad / Notas legales en cualquier momento. Recomendamos que examines esta política cada vez que visites el mismo.</p>
      <p>&nbsp;</p>
      <p><em>Esta Política de Privacidad se modificó por última vez y se publicó en nuestro Sitio Web el 1 de Noviembre de 2022.</em></p>
      <p>&nbsp;</p>
    </PrivacyContainer>
  )
}

export default Privacy
