import { useEffect, useState } from "react";
import styled from "styled-components";


export const DonationsWrapper = styled.div`
    background: transparent;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 30px auto;
    padding: 20px;
    font-size: 1.5em;
    max-width: 90%;
    word-break: break-word;
`

export const CarrouselWrapper = styled.div`
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 20px auto;
    overflow: hidden;

    & .previous, .next{
        top:50%;
    }

   
    & [name='carrousel-img'] {
        height: 100%;
    }
`

export const LoadingWrapper = styled.div`
    background: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: 1px 1px 6px 1px #4f4f4f;
`

export const InfoWrapper = styled.div`
    background: #4BAA31;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 30px auto;
    padding: 20px;
    box-shadow: 1px 1px 6px 1px #4f4f4f;
    color: #FFF;
    .text ul{
      margin-top: 10px;
      margin-left: 20px;
    }
    .text a{ color: white; font-weight:bold;}

    h1{
        color:white;
    }
`

export const SliderWrapper = styled.div`

    display: flex;
    gap: 5px;
    align-items: center;
    margin: 0 auto;
    justify-content:space-between ;

    .previous, .next{

    top: calc(50% - 50px);
        margin: 0 50px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-top: solid 5px #4BAA31;
        border-right: solid 5px #4BAA31;
        height: 30px;
        width: 30px;
        opacity: 0.75;

        @media screen and (max-width: 1020px){
            top: calc(30vh - 64px);
            height: 20px;
            width: 20px;
            margin: 0 25px;
        }
    }


    .previous{
        left: 0;
        transform: rotate(-135deg);
    }

    .next{
        right: 0;
        transform: rotate(45deg);
    }

    .img_thumb{
        border: 2px solid black;
        padding: 0px;
        margin: 0px;
        max-width: 100%;
        max-height: none;
        width: 116px;
        height: 100px;
        object-fit: cover;
    }
`

