//React
import {TiTimes} from 'react-icons/ti'
import {BiError,BiErrorCircle} from 'react-icons/bi'
import {GiCheckMark} from 'react-icons/gi'

//
import {
  Overlay,
  Popup,
  PopupTitle,
  PopupClose,
  PopupIcon,
  PopupContent,
  ActionPromptWrapper
} from './elements'

const ConfirmationPopUp = ({ show,title,content,closeHander,children, }) => {
    return (
    <Overlay show={show}>
      <Popup>
        <PopupTitle>{title}</PopupTitle>
        <PopupClose onClick={closeHander}>
          <TiTimes></TiTimes>
        </PopupClose>
        <PopupContent>
            {content}

        </PopupContent>
        <ActionPromptWrapper>
           {children}
        </ActionPromptWrapper>
      </Popup>
    </Overlay>
    )
}
export const ShowMessagePopUp = ({tipo="exito", show,title,content,closeHander,children }) => {
    if(show)
        setTimeout(closeHander, 2000)
    return (
    <Overlay show={show}>
      <Popup>
          <PopupIcon>
              {
                  tipo==="exito"?
                      <GiCheckMark size={"35px"} color={"green"}></GiCheckMark>
                      :
                      tipo==="warning"?
                          <BiError size={"40px"} color={"#dfb51d"}></BiError>
                      :
                          <BiErrorCircle size={"40px"} color={"#ce1c1c"}></BiErrorCircle>

              }

              <PopupTitle>{title}</PopupTitle>
          </PopupIcon>
        <PopupContent>
            {content}

        </PopupContent>
        <ActionPromptWrapper>
           {children}
        </ActionPromptWrapper>
      </Popup>
    </Overlay>
    )
}

export default ConfirmationPopUp
