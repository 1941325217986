//React
import React, {useCallback, useRef,useEffect, useState } from 'react'

//Styled Components
import {
    BigWrapper,
    ZoneSelector,
    SeccionImage,
    Wrapper,
    FilterWrapper,
} from './Elements'

//Components
import Donations from '../../components/Donations'
import Loading from "../../components/Loading";


//DatePicker
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import {API_URL} from "../../Settings/ApiSettings";
import {useDonations} from "../../hooks/useDonations";
import useNearScreen from "../../hooks/useNearScreen";

import debounce from 'just-debounce-it'
const DonationsPage = ({adminMode=false}) => {
    const [location, setLocation] = useState('');
    const [date, setDate] = useState(new Date());


    const { loading, donations,setDonations, setPage ,lastPage} = useDonations({ location, date })

    const externalRef = useRef()
    const {isNearScreen} = useNearScreen({
        externalRef: loading ? null : externalRef,
        once: false
    })
    function touch(e) {
        let sh = e.currentTarget.scrollHeight
        let st = e.currentTarget.scrollTop
        let r = e.currentTarget.clientHeight

        let res = (sh-st)-r;
        //margen de error de 8px
        const bottom = res <= 8;

        if(bottom && !lastPage){
             debounceHandleNextPage()
        }
    }
    function scroll(e) {
        let sh = e.target.scrollHeight
        let st = e.target.scrollTop
        let r = e.target.clientHeight

        let res = (sh-st)-r;
        //margen de error de 8px
        const bottom = res <= 8;

        if(bottom && !lastPage){
             debounceHandleNextPage()
        }
    }

    const debounceHandleNextPage = useCallback(debounce(
        () => setPage(prevPage => prevPage + 1), 200
    ), [setPage])

    useEffect(function () {
        if (isNearScreen) debounceHandleNextPage()
    }, [debounceHandleNextPage, isNearScreen])

    return (
        <>
            <Wrapper onTouchMove={touch} onWheel={scroll} >
                {
                    donations === undefined || donations.length === 0 ?
                        <BigWrapper>
                            <SeccionImage onClick={() => setLocation('Buenos Aires')} src={`${process.env.PUBLIC_URL}/imagenes/__Boton_grande_BsAs.png`} size={'large'}/>
                            <SeccionImage onClick={() => setLocation('San Luis')} src={`${process.env.PUBLIC_URL}/imagenes/__Boton_grande_SL.png`} size={'large'}/>
                        </BigWrapper>
                        :
                        <FilterWrapper>
                            <ZoneSelector>
                                <SeccionImage onClick={() => setLocation('Buenos Aires')} src={`${process.env.PUBLIC_URL}/imagenes/__Boton_grande_BsAs.png`} size={'small'}/>
                                <SeccionImage onClick={() => setLocation('San Luis')} src={`${process.env.PUBLIC_URL}/imagenes/__Boton_grande_SL.png`} size={'small'}/>
                                <div>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date)=>{setDate(date)}}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                    />
                                </div>
                            </ZoneSelector>
                        </FilterWrapper>

                }
                <div id={"visor"}>
                    {
                        loading ?
                            <Loading/>
                            :
                            <Donations setDonations={setDonations} adminMode={adminMode} ListaDonaciones={donations} />
                    }
                </div>
            </Wrapper>
        </>
    )
}

export default DonationsPage;
