//React
import  { useState,useEffect } from 'react'
import { useLocation } from "react-router-dom";

//Styled Components
import {
    BgDiv,
    Wrapper,
    NavContainer,
    NavLogo,
    SidebarLink,
    SidebarLinkFake,
    IconLogOut
} from './elements'

//Components
import BurguerButton from '../BurguerButton'

//Contexts
import useWindowDimensions from "../../contexts/windowDimensions"
import {useUserContext} from "../../contexts/UserContext";


function NavbarNew (){
    const [clicked, setClicked] = useState(false);
    const [menuActive, setMenuActive] = useState(null);
    const { user, logged,logOut } = useUserContext()
    const { width } = useWindowDimensions();
    const handleClick = () => {
        //cuando esta true lo pasa a false y vice versa
        setClicked(!clicked)
    }
    const location = useLocation();
    useEffect(() => {
        if(location.pathname !== '/')
            setMenuActive(location.pathname)
    }, [location])

    return(
        <Wrapper>
            <NavContainer>
                <NavLogo to='/'> <img src= {process.env.PUBLIC_URL +'/imagenes/Teaming_blanco-03.png' }  alt='Teaming Logo'/> </NavLogo>
                <NavLogo to='/'> <img id="cat-logo" src= {process.env.PUBLIC_URL +'/imagenes/NuevoLogoCAT_Negativo.png' }  alt='CAT Technologies Logo'/> </NavLogo>

            </NavContainer>
            <NavContainer>
                <div className={`links ${clicked && width <1221 ? 'active' : ''}`} onClick={() => {setClicked(false)}}>
                    {
                        logged && user.roles.findIndex(role => role.nombre === 'administrador') !== -1?
                            <>
                                <SidebarLinkFake className={menuActive === '/admin' ? 'selected' : ''} onClick={logOut}> <IconLogOut size={'35px'}/>  </SidebarLinkFake>
                                <SidebarLink className={menuActive === '/admin/cms' ? 'selected' : ''} to='/admin/cms'>CMS</SidebarLink>
                            </>
                            :
                            <></>

                    }
                    <SidebarLink className={menuActive === '/about' ? 'selected' : ''} to='/about'>Acerca de Teaming</SidebarLink>
                    <SidebarLink className={menuActive === '/DonationsPage' ? 'selected' : ''} to='/DonationsPage'>Nuestras Donaciones</SidebarLink>
                    <SidebarLink className={menuActive === '/sugeri' ? 'selected' : ''} to='/sugeri'>Sugerí una institución</SidebarLink>
                    <SidebarLink className={menuActive === '/joinUs' ? 'selected' : ''} to='/joinUs'>¡Sumate!</SidebarLink>

                </div>
                <div className='burguer'>
                <BurguerButton clicked={clicked} handleClick={handleClick} />
                </div>
                <BgDiv className={`initial ${clicked && width <1221 ? 'active' : ''}`}></BgDiv>
            </NavContainer>
        </Wrapper>
    )
}

export default NavbarNew

