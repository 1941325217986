import {
    LoadingWrapper,
    LoadingText,
    Spin,
} from './elements'


const Loading = ({ color = "#3c8dbc",text, children, iconSize}) => {
    return (
        <LoadingWrapper>
            <Spin size={iconSize} color={color}></Spin>
            <LoadingText>
                {text}
            </LoadingText>
            {children}
        </LoadingWrapper>
    )
}

export default Loading
