import {API_KEY, API_URL} from '../Settings/ApiSettings'

const fromApiResponseToDon = apiResponse => {
    const {data = []} = apiResponse
    if (Array.isArray(data)) {
        return data
    }else{
        return []
    }
}

export default function getDon({
                                    pageSize = 4,
                                    location = "Buenos Aires",
                                    date = new Date(),
                                    page = 0,
                                } = {}) {
    const apiURL = `${API_URL}/donaciones/list?location=${location}&page=${page}&pageSize=${pageSize}&date=${date}`
    return fetch(apiURL)
        .then((res) => res.json())
        .then(fromApiResponseToDon)
}
