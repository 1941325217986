//React
import { useEffect, useState, useContext } from "react"
import { useParams,useNavigate } from "react-router-dom"

//Styled Elements
import {
    DonationsWrapper,
    InfoWrapper,
    CarrouselWrapper,
    LoadingWrapper,
    SliderWrapper
} from "./elements"

//Components
import Loading from "../../components/Loading"
import ImageTest from "../../components/ImageTest"
import Carrouseldetalle from "../../components/Carrouseldetalle"

//Contexts
import Context from "../../contexts/DonationsContext"
import Button from "../../components/Button"
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaArrowLeft } from "react-icons/fa"
import useWindowDimensions from "../../contexts/windowDimensions"

//Services
const { url } = require('../../Services/constantes');

const Donation = () =>{
    const [dataDonation, setDataDonation] = useState(null)
    const { getById } = useContext(Context)
    let {id} =  useParams()
    const {width} = useWindowDimensions()
    const navigate = useNavigate()
    //galeria
    const [slider, setSlider] = useState(null)

    const pageByWidth = () => {

        if (width > 768){
            return 5
        }
        else {
            if (width<=768 && width >425){
                return 3
            }else{
                return 1
            }
        }
    }

    useEffect(() =>{ //Consulta a la base de datos por los datos de la donacion
        getById(id, (result) => {
            let donation = result.data;
                if(typeof donation.date == 'string')
            donation.date = new Date(donation.date);

            donation.videos = []
            donation.carrousel =[]

            let gallery_count = 0, current_gallery = 1,pageSize = pageByWidth(),
            gallery = {
                page: 1,
                images: [],
            }, galleries = [];
            donation.imagenes.forEach(imagen => {

                if (imagen.tipo !== 'video'){
                    donation.carrousel.push(imagen);
                    gallery.images.push(imagen);
                    gallery_count++;

                    //llegamos al limite establecido por la paginacion, comenzamos con la siguiente pagina
                    if (gallery_count === pageSize){

                        gallery_count = 0;
                        current_gallery++;
                        galleries.push(gallery);
                        gallery = {
                            page: current_gallery,
                            images: [],
                        }
                    }
                } 
                else{
                    donation.videos.push(imagen);
                }
            });

            if (gallery_count != 0){
                galleries.push(gallery)
            }

            setSlider({
                pageSize : pageSize,
                slider:galleries
            });

            setDataDonation(donation);
        })
    },[id])

    return(
        <>
            {
                dataDonation?
                    <DonationsWrapper>
                        <Button size={'small'} color={"green"} onClickHandle={() => {navigate('/DonationsPage')}}>
                            <FaArrowLeft/>
                        </Button>
                        <InfoWrapper>
                            <h1>{dataDonation.title}</h1>
                            <p><b>Fecha:</b> {dataDonation.date.getDate() + '/' + (dataDonation.date.getMonth()+1) + '/' + dataDonation.date.getFullYear()}</p>
                            <p><b>Monto entregado:</b> ${dataDonation.amount}</p>
                            <div className={'text'} dangerouslySetInnerHTML={{__html:dataDonation.text}}>
                            </div>
                            {

                                dataDonation.videos.length > 0?
                                    dataDonation.videos.map(video =>{
                                        return (
                                            <iframe width="100%" height="315"
                                                    src={video.url}>
                                            </iframe>
                                        )
                                    })
                                    :
                                    <></>
                            }
                        </InfoWrapper>
                        {
                            dataDonation && dataDonation.carrousel.length > 0 ?
                                <CarrouselWrapper>
                                    <Carrouseldetalle images={dataDonation.carrousel} slider={slider.slider} sliderPageSize={slider.pageSize}/>
                                </CarrouselWrapper>
                            :
                                dataDonation.videos.length > 0?
                                    dataDonation.videos.map(video =>{
                                        return (
                                            <iframe width="420" height="315"
                                                    src={video.url}>
                                            </iframe>
                                        )
                                    })
                                    :
                                    <></>
                        }
                    </DonationsWrapper>
                    :
                    <LoadingWrapper>
                        <Loading iconSize={'60px'}/>
                    </LoadingWrapper>
            }

        </>
    )
}

export default Donation
