//React
import { useState, useEffect } from 'react'
import { useNavigate,Link } from 'react-router-dom';

//Styled Components
import {
  CarrouselWrapper,
  ButtonWrapper,
  StyledLink,
  BannerLinkWrapper
} from './elements'
import DonacionTarjeta from "../DonacionTarjeta";
import BannerLink from '../BannerLink';

//Services
const { url } = require('../../Services/constantes');
const navigate = useNavigate;

const Carrousel = ({ downButtons = false, images }) => {
  const [index, setIndex] = useState(0)

  const selectNewImage = (next = true) => {
    const condition = next ? index < images.length - 1 : index > 0
    const nextIndex = next ? (condition ? index + 1 : 0) : (condition ? index - 1 : images.length - 1)
    setIndex(nextIndex)
  }

  const selectClass = (pos) => {
    if(pos === index)
      return 'presentImg'
    if(index === 0 && pos === images.length - 1)
      return 'previousImg'
    if(index === images.length - 1 && pos === 0)
      return 'nextImg'
    if(pos === index - 1)
      return 'previousImg'
    if(pos === index + 1)
      return 'nextImg'
    return 'notInImg'
  }
  useEffect(() => {
    const interval = setInterval(() => {
      selectNewImage()
    }, 5000)

    return () => {
      clearInterval(interval);
    };
  });
  return (
    <CarrouselWrapper>
      {
        images.map((value, index) => {

          return(
            value.tipo==='lastDonation'?
              <DonacionTarjeta name='carrousel-img' className={selectClass(index)} />
            :
              value.tipo==='banner_link'?
              <BannerLinkWrapper>
                <Link key={index} className={selectClass(index)} to="/sugeri" name={'carrousel-img'} >
                  <img src={`${url.slice(0,-4)}${value.url}`} alt={'sugeri'} className={'banner_link'}/>
                </Link>
              </BannerLinkWrapper>
                
                
              :
                <img name={'carrousel-img'} key={index} className={selectClass(index)} src={`${url.slice(0,-4)}${value.url}`} alt={'img'}/>
          )
        })
      }
      <div className={'previous'} onClick={() => selectNewImage(false)}/>
      <div className={'next'} onClick={selectNewImage}/>
      {
        downButtons ?
          <ButtonWrapper>
            {
              images.map((value, indexArray) => {
                return(
                  <button key={value + indexArray} className={indexArray === index ? 'selected' : ''} onClick={() => setIndex(indexArray)}></button>
                )
              })
            }
          </ButtonWrapper>
        :
          ''
      }
    </CarrouselWrapper>
  )
}

export default Carrousel
