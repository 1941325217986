import styled from "styled-components"

export const DonationsData = {ids : [1, 2, 3]}

export const DonationsWrapper=styled.div`
    background: transparent;
    //display: grid;
    //align-items: flex-start;
    justify-content: center;
    //height: 900px;
    border-radius: 20px;
    margin: 30px 30px;
    padding: 20px;
    font-size: 1rem;
    
    @media screen and (max-width: 600px) {
        //width: 100%;
        display: block;
        //margin-bottom: 20px; 
    }

`
export const ZoneSelector = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
    gap:25px;
    //padding: 10px;    
    @media screen and (max-width: 600px) {
        width: 100%;
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: space-around; 
    }
  .react-datepicker-wrapper{
    width: calc(100% - 200px);
    border-radius: 0;
    outline: none;
    & input{ 
      padding: 5px;
      border: 2px solid #4BAA31;
      border-radius: 8px;
    }
  } 
`
export const BigWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;    
`
export const Wrapper = styled.div`
    margin-top: 10px;
    min-height: 80vh; 
    display: flex;
    flex-direction: column; 
    gap: 40px;
    overflow: auto; 
`
export const FilterWrapper = styled.div`  
    display: flex;
    flex-direction: column; 
    gap: 40px; 
    align-items: center;
    justify-content: center;
`


export const SeccionImage = styled.img`
    width: ${props => props.size === 'small' ? '100px' : '30vw'};
    :hover {
        background: rgb(166 255 131 / 60%);
        box-shadow: 0px 0px 10px 5px rgb(166 255 131 / 60%);
    }
    
`
