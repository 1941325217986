//React
import { useState } from 'react'

//Styled Components
import {
    Container,
    ButtonWrapper, Wrapper
} from './SuggestElements'

//Components
import Section from '../../components/Section'
import Button from '../../components/Button'
import Form from '../../components/Form'
import HeartContainer from '../../components/HeartContainer'
import ConfirmationPopUp from '../../components/ConfirmationPopUp'
import Loading from '../../components/Loading'
import {FormComponents,required} from "./Suggest-formdef"
import {API_URL} from "../../Settings/ApiSettings";

const Suggest = () => {
    const [form, setForm] = useState({})
    const [loading, setLoading] = useState(false)
    const [success,setSuccess] = useState(false)
    const [error,setError] = useState(false)

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

    const validate = () => {
        let result = true

        required.forEach(key => {

            if(!form.hasOwnProperty(key))
                result = false
            else{

                if(form[key].length === 0)
                    result = false
            }
        })

        result = isValidEmail(form.email);

        if(!result)
        {
            setError(true)
        }
        return result
    }

    const handleChange = e => {
        let aux = {
            ...form,
            [e.target.id]: e.target.value
        }
        setForm(aux)
    }

    const closePopUp = () => {
        setError(false)
    }

    const sendForm = e => {
        e.preventDefault()

        if(validate(form, setError))
        {
            setLoading(true);
            fetch(`${API_URL}/form/save/sugerir`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify (form)
            })
                .then(() => {
                    setSuccess(!success)
                    setLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch(err => console.error(err))
        }
    }

    return (
        <>
            <Wrapper>
                    <HeartContainer noText={true}>
                        <Container>
                            <h1 className={'title'}>Sugerí una institución</h1>
                            {
                                loading?
                                    <Loading/>
                                    :
                                    <>
                                        <Form formData={form} component={FormComponents} handleChange={handleChange}/>
                                        <ButtonWrapper>
                                            <Button size={'small'} color={"white"} onClickHandle={sendForm}>
                                                Enviar
                                            </Button>
                                        </ButtonWrapper>
                                    </>
                            }
                        </Container>
                    </HeartContainer>
            </Wrapper>
            <ConfirmationPopUp show={success} title={'Sugerir institución'} content={'El formulario se envió correctamente.'} closeHander={() => setSuccess(!success)}></ConfirmationPopUp>
            <ConfirmationPopUp show={error} closeHander={closePopUp} title={'¡Error!'} content={"Complete los campos obligatorios (nombre, apellido, e-mail, institución, términos y condiciones).\nChequee la validez del correo. "}>
                <Button size={'small'} onClickHandle={closePopUp}>
                    Continuar
                </Button>
            </ConfirmationPopUp>
        </>
  )
}

export default Suggest
