import styled from "styled-components";
import { NavLink as Link} from 'react-router-dom'



export const Nav = styled.div`
  background: #40a52b00; 
  width: 100%;
  font-size: 0.8rem;
  display:flex;
  justify-content: center;
  padding-bottom: 25px;
  a{ 
    color: #212529;
    font-weight: bold;
  }
  strong { 
    font-size: 1rem;
    color: #212529; 
  }
`

export const Contact = styled.div`
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    cursor: pointer;
    p{
        margin: 0;
        padding: 0 5px;
    }
`

export const NavMenu = styled.div`
  background: rgba(201, 201, 201, 0.30);
  border-radius: 25px;
  display: flex;
  align-items: center;
  list-style: none;
  z-index: 1;
  white-space: nowrap;
  @media (max-width: 490px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
`

export const NavItem =styled.li`
`

export const Navlinks =styled(Link)` 
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    z-index: 1; 
   :hover{
     color: #40a52b;
     
   }
    &.active{
      color: #40a52b;
    }
`
