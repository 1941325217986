import styled from "styled-components"

export const Overlay = styled.div`
    visibility:  ${props => props.show ? "visible" : "hidden"};
    opacity: ${props => props.show ? 1 : 0};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3050; 
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
`
export const Popup = styled.div`
    margin: 70px auto; 
    background: #fff;
    border-radius: 5px; 
    width: content-box;
    padding: 15px 40px; 
    width: fit-content;
    position: relative;
    transition: all 5s ease-in-out;
    color: black;
    h2{
      color: black;
    }
    @media screen and (max-width: 700px) {
    }
`

export const PopupTitle = styled.h2`
    margin-top: 0;
    color: #333;
    font-size: 25px;
    font-family: Tahoma, Arial, sans-serif;
`
export const PopupClose = styled.span`
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;

    &:hover{
        cursor: pointer;
        color: #000;
    }
`
export const PopupIcon = styled.span`
    font-size: 30px;
    display: flex;
    gap: 20px;
`

export const PopupContent = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 30%;
    white-space: break-spaces;
    overflow: auto;
`
export const ActionPromptWrapper = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: start;
`
