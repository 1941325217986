import styled from "styled-components"
import { Link } from "react-router-dom"

export const AddForm = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 20px;
`

export const FormItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    align-items: start;
    font-size: 14px;
    color: white;

    p{ 
        padding-right: 15px;
        text-align: start;
        font-weight: bold;
    }

    input[type="text"]{
        color: black;
        width: 100%;
      padding: 5px 6px;
        border-radius: 0;
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
    }

    input[type="text_hidden"]{
        width: 100%;
        padding: 8px 12px;
        border-radius: 0;
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
    }

    textarea{
        color: black;
        width: 100%;
        padding: 8px 12px;
        min-height: 70px;
        border-radius: 0;
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
    }

    .radio_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;
        
        p{
            width: fit-content;
        }
    }

    .radio_container_box{
      display: block;
      overflow: auto;
      max-height: 70px;
      padding: 5px;
      border-radius: 5px;
      border: 1px solid #cccccc;
      flex-wrap: nowrap;
      align-items: flex-start;
        p{
            width: fit-content;
        }
          /* width */
          ::-webkit-scrollbar {
            width: 5px;
          }
    
          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
    
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888;
          }
    
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
    }

    input[type="radio"]{
        width: 20px;
    }

    select{
        width: calc(100% - 200px);
        padding: 8px 12px;
        border-radius: 0;
        outline: none;
        border: 1px solid #ccc;
    }

    .react-datepicker-wrapper{
        width: calc(100% - 200px);
        border-radius: 0;
        outline: none;

        & input{
            width: 100%;
        }
    }

    input[type="checkbox"]
    {
        vertical-align:middle;
    }

    label{
        display: inline-block;
        vertical-align: middle;
        font-weight: bold;
    }
`
export const FormItemCheck = styled.div`
width: 100%;
display: flex;
gap: 5px;
padding: 0 80px 0 0;
align-items: center;
font-size: 14px;
color: #555;
line-height: 1.42857143;

p{
    width: 200px;
    padding-right: 15px;
    text-align: end;
    font-weight: bold;
}

input{
    width: 10px;
    padding: 8px 12px;
    border-radius: 0;
    outline: none;
    border: 1px solid #ccc;
}
`

export const FormItemRadio = styled.div`
width: 100%;
display: flex;
gap: 5px;
padding: 0 80px 0 0;
align-items: center;
font-size: 14px;
color: #555;
line-height: 1.42857143;

p{
    width: 200px;
    padding-right: 15px;
    text-align: end;
    font-weight: bold;
}

input{
    width: 10px;
    padding: 8px 12px;
    border-radius: 0;
    outline: none;
    border: 1px solid #ccc;
}
`

export const StyledLink = styled(Link)`
    padding: 5px 5px 5px 25px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    color: #8aa4af;
    font-size: 14px;
    text-decoration: none;

    &:hover{
        color: white;
    }
`

export const FormButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
