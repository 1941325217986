//React

import React, {useCallback, useEffect, useState} from 'react';
import {format} from 'date-fns';
//Styled Elements
import {
  Card,
    DonationsWrapper,TrashFiles,
  Navlinks
} from './elements';
import ImageTest from '../ImageTest';
import {useNavigate} from "react-router-dom";
const { url} = require('../../Services/constantes');

//Listado de donaciones segun localidad de donaciones
const DonationCard = (({ adminMode=false,donation }) => {
  const navigate = useNavigate()
  return (
        <>
            {
                donation?

                    <Card className={'donation-card'}>
                        <ImageTest images={donation.miniaturas===undefined?donation.imagenes.filter(image=>image.miniatura):donation.miniaturas}/>

                        <div className={'text'}>
                          <div className={'row'}>
                            <span id={'left'}>{format(typeof donation.date === 'string'? new Date(donation.date):donation.date,'dd / MM / yyyy') }</span>
                            <span id={'rigth'}>{donation.institucion}</span>
                          </div>
                          <h4>{donation.title}</h4>
                          <p>{donation.resumen}</p>
                        </div>
                    </Card>
                    :
                    <Card >
                        <img src={process.env.PUBLIC_URL +'/imagenes/Demo.jpg' }  alt="Miniatura"/>
                        <div>
                            <h6>Demo de una tarjeta</h6>
                            <span>un resumen que se pueda visualizar en la tarjeta</span>
                        </div>
                    </Card>
            }
      </>
  )
})

export default DonationCard;
