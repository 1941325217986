//React
import { useState, useEffect } from 'react'

//Styled Components
import {
  ImageTestWrapper,
  ButtonWrapper
} from './elements'
const { url } = require('../../Services/constantes');

const ImageTest = ({ images=[],downButtons = false }) => {
  const [index, setIndex] = useState(0)
  const [loop, setLoop] = useState(false);
  function startChangeLoop() {
    setLoop(1);
  }
  function stopChangeLoop() {
    setLoop(false);

  }
  const selectNewImage = (next = true) => {
    const condition = next ? index < images.length - 1 : index > 0
    const nextIndex = next ? (condition ? index + 1 : 0) : (condition ? index - 1 : images.length - 1)
    setIndex(nextIndex)
  }

  const selectClass = (pos) => {
    if(pos === index)
      return 'presentImg'
    if(index === 0 && pos === images.length - 1)
      return 'previousImg'
    if(index === images.length - 1 && pos === 0)
      return 'nextImg'
    if(pos === index - 1)
      return 'previousImg'
    if(pos === index + 1)
      return 'nextImg'
    return 'notInImg'
  }
  useEffect(() => {
    if(loop ===false)
      return;
    const interval = setInterval(() => {
      selectNewImage()
      setLoop(loop+1);
    }, 2000)

    return () => {
      clearInterval(interval);
    };
  },[loop]);

  return (
    <ImageTestWrapper onMouseOver={startChangeLoop}
                      onMouseOut={stopChangeLoop} >
      {
        images.map((value, index) => {
          return(
            <img key={index} className={selectClass(index)}
                 src={`${value.url === undefined ? URL.createObjectURL(value) : `${url.slice(0,-4)}${value.url}`}`}
                 alt={'img'}/>
          )
        })
      }
      {
        /*
            <div className={'previous'} onClick={() => selectNewImage(false)}/>
          <div className={'next'} onClick={selectNewImage}/>
          {
            downButtons ?
              <ButtonWrapper>
                {
                  images.map((value, indexArray) => {
                    return(
                      <button key={value + indexArray} className={indexArray === index ? 'selected' : ''} onClick={() => setIndex(indexArray)}></button>
                    )
                  })
                }
              </ButtonWrapper>
            :
              ''
          }
         */
      }
    </ImageTestWrapper>
  )
}

export default ImageTest
