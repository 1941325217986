import styled from 'styled-components'

export const StyledInput = styled.input` 
    width: 100%;
    border: none;
    border-bottom: 2px solid;
    border-radius: 5px;
    border-color: ${({ color }) => color ? color : ' #40a52b'};
    outline: none;
    padding: 5px;
    font-size: 18px;
    color: #000000;
    &::placeholder{
        color:${({ color }) => color ? color : ' #40a52b'};
    }

  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  &:invalid{
    border-bottom: 2px solid red;
  }
`

export const Wrapper = styled.div`
    margin: 15px 0;
    width: ${({ width }) => width ? width : '100%'}; 
    color: ${({ color }) => color ? color : ' '};
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0; 

  h2{
    font-size: 18px; 
    font-weight: 300;
  }
  label{
    font-weight: bold;
  }
`

export const Option = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 16px;

  input{
    width: fit-content;
  }
`
