import styled from "styled-components";
import { NavLink as Link} from 'react-router-dom'

export const Cards = styled.div`
    border-radius: 5px;  
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 10px; 
    
    @media screen and (max-width: 600px) {
        display: block;
    } 
`

export const DonationsWrapper = styled.div`    
  max-width: 250px;
  min-width: 250px;

`

export const Card = styled.div`
    min-width: 280px;
    max-width: 280px;
    border-radius: 15px; 
    flex-direction: column; 
    min-height: 315px;
    text-align: center;
    background-color: #ffffff; 
    img,div{ 
      max-width: 280px;
    }
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 60%); 
    text-overflow: ellipsis;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        display: block;
        margin-bottom: 20px; 
    }
    span{ 
      width: 100%; 
      font-size: 12px; 
      text-align: initial;
      color: gray;
    }
    p, span , h4{ 
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .row{
      display: flex;
      justify-content: space-between;
      width: 100%;
      #rigth{
        text-align: end;
        padding-right: 10px;
      }
      #left{
        text-align: start; 
      }
    }
    p{ 
      color: gray;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box; 
    }

    
    div{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
      padding: 0px 0 5px;
    }
      .text{ 
        padding:10px;
      }
  
`

export const Navlinks =styled(Link)`
    cursor: pointer;
    color: black;
`
