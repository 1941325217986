import {
    LogoWrapper
} from './elements'

import {useEffect,useState} from "react";
const Logo = ({ backgroundColor ,width,height, imgroute}) => {
    const [loading, setLoading] = useState(`${process.env.PUBLIC_URL+imgroute}`)
    const setBuffer = (buffer) =>{
        const b64 = btoa(String.fromCharCode(...new Uint8Array(buffer.data)));
        const mimeType =  "image/png"
        document.getElementById('logo').src = `data:${mimeType};base64,${b64}`
    }
    useEffect(()=>{
        let patDest = `${process.env.PUBLIC_URL}/imagenes/Teaming-03.png`;
        //ImageService.resizePublicImage(`${process.env.PUBLIC_URL}/assets/images/logo-negro.png`,patDest,250,250,'inside',setBuffer);
    },[])
    return (
        <LogoWrapper backgroundColor={backgroundColor} width={width} height={height}>
            <img id="logo" src={loading} alt="LOGO"/>
        </LogoWrapper>
    )
}

export default Logo
