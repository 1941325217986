import React, {useState} from 'react'

//Service

import DonacionService from '../Services/DonacionesService';

import { useUserContext } from './UserContext'

const Context = React.createContext({})

export function DonationContextProvider ({children}) {
    const { user, logged } = useUserContext()
    const [donations, setDonations] = useState([])
    const [nuevaDonation, setNuevaDonation] = useState(
        {
            title: null,
            multimedia:[],
            miniaturas:[],
            imagenes:[],
            institucion: null,
            resumen: null,
            text: null,
            date: new Date(),
            amount: '',
            video:'No',
            link_video:'',
            state: '1',
            location: 'Buenos Aires'
        }
    )
    const [donationsLoading, setDonationsLoading] = useState(false)
    const [donationsCreating, setDonationsCreating] = useState(false)
    const [donationsDeleting, setDonationsDeleting] = useState(false)
    const [error, setError] = useState({error:false});
    const [exito, setExito] = useState({status:false});

    const changeHandler = (obj, name) => {
        let newFields = {
            [name]: obj

        }

        if(name === 'imagenes'){
            let min = [];
            let mul = [];
            for (var i=0, n=obj.length; i < n; ++i ) {
                if(obj[i].tipo === 'miniatura'){
                    min.push(obj[i].file===undefined?obj[i] : obj[i].file);
                }
                if(obj[i].tipo === 'multimedia'){
                    mul.push(obj[i].file===undefined?obj[i] : obj[i].file);
                }
            }
            newFields.miniaturas=min;
            newFields.multimedia=mul;
        }
        setNuevaDonation({
            ...nuevaDonation,
            ...newFields
        })
    }

    const limpiarDonation = () => {
        setNuevaDonation({
            title: null,
            multimedia:[],
            miniaturas:[],
            imagenes:[],
            institucion: null,
            resumen: null,
            text: null,
            date: new Date(),
            amount: '',
            video:'No',
            link_video:'',
            state: '1',
            location: 'Buenos Aires'
        })
    }

    const validateDonation = () => {
        let res = 'Revise los siguientes campos obligatorios: \n';
        let tamOrg = res.length;

        if(nuevaDonation.title === null || nuevaDonation.title.trim().length===0){
            res += '- Título.  \n';
        }
        if(nuevaDonation.institucion === null || nuevaDonation.institucion.trim().length===0){
            res += '- Institución. \n';
        }
        if(nuevaDonation.amount === null || nuevaDonation.amount.trim().length===0){
            res += '- Debe ingresar el monto de la donación. \n';
        }
        if(nuevaDonation.video === 'Si'){
            if(nuevaDonation.link_video.trim().length===0)
                res += '- Falta la url del video. \n';
            else{
                const searchParams = new URLSearchParams(nuevaDonation.link_video);
                if (searchParams.get('https://www.youtube.com/watch?v')){
                    nuevaDonation.link_video = `https://www.youtube.com/embed/${searchParams.get('https://www.youtube.com/watch?v')}`
                }
                else{
                    res += 'La url del video es incorrecta. Debe tener el formato "https://www.youtube.com/watch?v"... ';
                }
            }
        }
        if(nuevaDonation.miniaturas.length===0){
            res += '- Una miniatura como minimo. \n';
        }

        
        return res.length > tamOrg? res:true;

    }

    const createDonation = () => {
        if(!logged){
            return;
        }

        setDonationsCreating(true)

        
        DonacionService.create(nuevaDonation,user.token, (donations) => {
            if(typeof donations === 'object'){
                //limpiarDonation()
                setExito({status:true,message:"La donación fue creada con exito."})
            }else{
                setError({error:true,message:donations});
            }
            setDonationsCreating(false)
        })
    }

    const deleteDonation = (id) => {
        if(!logged){
            return;
        }

        setDonationsDeleting(true)

        DonacionService.deleteDonacion(id,user.token, (res) => {
            setDonationsDeleting(false)
        })
    }

    const deleteVideo = (id) => {
        if(!logged){
            return;
        }
        DonacionService.deleteVideo(id,user.token, (resultado) => {

        })
    }
    
    const deleteImage = (id) => {
        if(!logged){
            return;
        }
        DonacionService.deleteImage(id,user.token, (resultado) => {
            if(typeof donations === 'object'){
                setExito({status:true,message:"La imagen fue eliminada correctamente."})
            }else{
                setError({error:true,message:donations});
            }
        })
    }

    const editDonation = () => {
        if(!logged){
            return;
        }
        setDonationsCreating(true)
        DonacionService.editDonacion(nuevaDonation,user.token, (donations) => {
            if(typeof donations === 'object'){
                setExito({status:true,message:"La donación fue editada con exito."})
            }else{
                setError({error:true,message:donations});
            }
            setDonationsCreating(false)
        })
    }

    const setNuevaDonationById = (id) => {
        setDonationsLoading(true)
        DonacionService.getById(id,(result) => {
            let donation = result.data;
            donation.miniaturas = [];

            if(typeof donation.date == 'string')
                donation.date = new Date(donation.date);

            donation.imagenes.forEach(imagen => {
                if(imagen.tipo === 'video'){
                    donation.video = 'si';
                    donation.link_video = imagen.url;
                }else{
                    imagen.tipo = imagen.miniatura? 'miniatura':'multimedia';
                    if (imagen.miniatura){
                        donation.miniaturas.push(imagen);
                    }
                }
            });
            setNuevaDonation(donation)
            setDonationsLoading(false)
        })
    }

    const getDonations = () => {
        setDonationsLoading(true)
        DonacionService.getAll((donations) => {
            setDonations(donations)
            setDonationsLoading(false)
        })
    }

    const getById = (id, callback) => {
        DonacionService.getById(id, callback)
    }

    return (
        <Context.Provider value={{
            donations,setDonations,
            nuevaDonation,error,setError,
            exito,setExito,
            donationsLoading,
            donationsCreating,
            donationsDeleting,
            setNuevaDonation,
            changeHandler,
            validateDonation,
            createDonation,
            editDonation,
            deleteDonation,
            deleteVideo,
            deleteImage,
            setNuevaDonationById,
            limpiarDonation,
            getDonations,
            getById
        }}>
            { children }
        </Context.Provider>
    )
}

export default Context
