import styled from "styled-components";
import { NavLink as Link} from 'react-router-dom'

import {
    BiEdit
} from 'react-icons/bi'
import {FaTrashAlt} from "react-icons/fa";


export const Cards = styled.div`
    border-radius: 5px;  
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 10px; 
    
    @media screen and (max-width: 600px) {
        display: block;
    } 
`

/*
export const DonationsWrapper = styled.div` --gridList-column: 150px;
  --gridList-rows: 350px;
  --gridList-gap: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--gridList-column), 1fr));
  grid-auto-rows: var(--gridList-rows);
  grid-template-rows: masonry;
  grid-auto-flow: row dense;
  grid-gap: var(--gridList-gap, 8px);
  gap: var(--gridList-gap, 8px);
  align-items: center;`
 */
export const DonationsWrapper = styled.div`    
  display: flex;
  font-size: 18px;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly; 
`

export const Card = styled.div`
    position: relative;
    
`

export const Navlinks =styled(Link)`
    cursor: pointer;
    color: black;
`
export const Wrapper =styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  height: 60px;
  z-index: 2;
  :hover{

    svg{
      width: 50px;
      height: 50px;
      padding: 8px;
    }
    .left-icon{ 
      right: 60px;
    }
  }
`

export const Trash  = styled(FaTrashAlt)` 
    font-size: 25px;
    color: grey;
    background-color: white; 
    width: 25px;
    height: 25px;
    padding: 1px;
    border-radius: 7px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 3;
    margin: 5px;
    cursor: pointer;
  transition: all 500ms ease-in-out;
  :hover{

    width: 50px;
    height: 50px;
    padding: 8px;
  }
  @media screen and (max-width: 600px) { 
    width: 50px;
    height: 50px;
    padding: 8px;
  }
`

export const Edit = styled(BiEdit)`
  font-size: 30px;
  color: white;
  background-color: #ffa600;
  width: 25px;
  height: 25px;
  padding: 1px;
  right: 40px;
  border-radius: 7px;
  position: absolute;
  top: 5px;
  margin: 5px;
  z-index: 3;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  @media screen and (max-width: 600px) {
      width: 50px;
      height: 50px;
      padding: 8px;
      right: 60px;
    }
  
`
