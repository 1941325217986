//React
import { useState, useEffect } from 'react'

//Components
import Section from "../../components/Section"
import Carrousel from "../../components/Carrousel"

//Service
import CarrouselService from "../../Services/CarrouselService"


const Home = ()=>{
    const [images, setImages] = useState([])
    
    useEffect(()=>{
        CarrouselService.getAll(true,(result)=>{
            setImages(result);
        })
    },[])

    return(
        <Section>
            <Carrousel images={images}/>
        </Section>
    )
}

export default Home