import styled from "styled-components";

export const LastDonationWrapper = styled.div`
    background: transparent;
    display: grid; 
    justify-content: center; 
    border-radius: 20px;
    margin: 30px 30px;
    padding: 20px;
    
    
`
export const HelpContainer = styled.div`
  background: #40a52b;
  color: #FFF;
  border-radius: 20px;
  margin: 30px 30px;
  padding: 20px;
  font-size: 1.5em;
  display: flex;
  gap: 20px;
  flex-direction: column;
  line-height: 40px ;
  text-align: left;
  ol {
    list-style-position: inside;
  }

  a{
    color: #FFF;
    font-weight: bold;
    font-style: italic;
  }
  h1{
    color:white;
    font-size: 1.8em;
  }
`
