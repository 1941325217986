//React
import React from 'react'

//Styled Components
import {
    Container,
    LinkStyled,
    FlexDiv
} from './JoinUsElements'

//Components
import Section from '../../components/Section'
import Button from '../../components/Button'
const JoinUs = () => {
  return (
        <Section>
            <Container>
                <FlexDiv>
                    <h1>¡Sumate!</h1>
                    <p>Para sumarte o modificar el monto de tu donación ingresá a CATnet</p>
                </FlexDiv>
                <Button size={'small'} color={"white"} onClickHandle={() => {window.location.href = "http://intranet2.cat/catnet/#/"}}>
                    CATnet (Site)
                </Button>
                <Button size={'small'} color={"white"} onClickHandle={() => {window.location.href = "https://service01.cat-technologies.com:4433/CATSistem"}}>
                    CATnet (Remoto)
                </Button>
            </Container>
        </Section>
  )
}

export default JoinUs
