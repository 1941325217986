import styled from "styled-components";

export const AboutWarpped = styled.div` 
    font-size: 1em; 
    color:white;
    h1{
        color:white;
        font-size: 2em;
    }

    h3{
        font-size: 1.2em;
    }

    p{
        line-height: 25px;
    }
`
