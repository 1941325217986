import styled from 'styled-components'

export const Overlay = styled.div`
    width: 100vw;
    height: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    padding: 40px;
    z-index: 2;

    display: flex;
    align-items: flex-start;
    justify-content: center;

`
let pantalla = window.innerHeight
export const ContenedorModal = styled.div`
    //width: 500px;
    min-height: 100px;
    height: ${ 500 + 'px'};
    background: #fff;
    position: relative;
    border-radius: 5px;
    box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
    padding: 20px;

    img{
  width: 100%;}
`

export const EncabezadoModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;//Si hay mas de un elemanto en el encabezado pone un elemento a izquierda y el otro a derecha
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E8E8E8;

    h3{
        font-weight: 500;
        font-size: 16px;
        color: #1766DC;
    }
`

export const BotonCerrar = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: none;
    background: #4BAA31;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 5px;
    color: #fff;

    &:hover{
        background: #f2f2f2;
    }
`
