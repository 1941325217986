import styled from "styled-components"

export const StyledButton = styled.button`
    padding: ${props => props.size === 'small' ? '4px 25px' : '10px 35px'};
    align-self: ${(props) => props.align ? props.align : 'center'};
    cursor: pointer;
    border: none;
    outline: none;
    ${props => props.color =='white' ?
            'color:#45a731;'+
            'background-color:white;' +
            '&:hover{' +
            'background-color: #606060;' +
            'color:white;  }'
            :
            props.color=='green'?
                    'color:white;'+
                    'background-color:#4BAA31;' +
                    '&:hover{' +
                    'background-color: #4BAA3180;' +
                    '  }'
                    :
                    'color:white;'+
                    'background-color:#46B926;' +
                    '&:hover{' +
                    'background-color: #4BAA31;' +
                    '  }'} 
    font-weight: 600;
    border-radius: 15px;
    margin: 5px;
    display: flex;
    align-items: center;
    min-width: 70px;
    gap: 3px;
    

    
    &:disabled,[disabled]{
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
      }
`
