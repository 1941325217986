import {API_URL} from "../Settings/ApiSettings";

const images = [
    {
    url:'imagen1.png',
    tipo:'imagen',
    posicion:0,
},{
    url:'imagen2.png',
    tipo:'imagen',
    posicion:1,
},{
    url:'',
    tipo:'lastDonation',
    posicion:2,
} ]
const {API_KEY, url} = require('./constantes');
function formDataConst(listImages) {
    //Creamos el form data
    const formData = new FormData()
    let x = 0;
    listImages.forEach(file =>{
        let multimetadata = {
            id : file.id? file.id : null,
            tipo:file.tipo,
            posicion:file.posicion,
            active:true
        };
        if(file.url !== undefined){
            multimetadata.url = file.url;
            formData.append('imagenes', JSON.stringify(multimetadata));
            return;
        }
        formData.append('files', file.file)
        multimetadata.posFile =x;
        formData.append('imagenes', JSON.stringify(multimetadata));
        x++;
    })
    return formData;
}
async function create(imagenes,token, callBack) {
    try{
        //Creamos el form data
        const formData =  formDataConst(imagenes);
        // send `POST` request
        await fetch(`${url}/carrousel/create`, {
            method: 'POST',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: formData,
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.status==='SUCCESS'?json.data:json.message))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function changeState(id,pos,token, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/carrousel/update/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            },
            body: JSON.stringify({id:id,pos:pos}),
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}
async function getAll(admin = false,callBack) {
    try{
        // send `POST` request
        await fetch(`${API_URL}/carrousel/list${admin?'':'?pos=0'}`, {
            method: 'GET',
            headers: {
                'authorization': 'Bearer '+ API_KEY
            }
        })
        .then(res => {
            return res.json()
        })
        .then(json => callBack(json.data))
        .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

async function deleteImage(id,token, callBack) {
    try{
        // send `DELETE` request
        await fetch(`${url}/carrousel/delete/${id}`, {
            method: 'DELETE',
            headers: {
                'authorization': 'Bearer '+ token
            }
        })
            .then(res => {
                return res.json()
            })
            .then(json => callBack(json.status==='SUCCESS'?json.data:json.message))
            .catch(err => console.error(err))
    }catch (e){
        console.log(e);
    }
}

// send `POST` request
const DonacionService = {
    create,
    changeState,
    deleteImage,
    getAll,
};


export default DonacionService
