import { useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { useUserContext } from '../../contexts/UserContext'

const ProtectedPath = ({ children, allowedRoles }) => {
    const { user, logged , logOut} = useUserContext()
    const navigate = useNavigate()
    if(logged && user && user.roles === undefined)
        logOut()
    useEffect(() => {
        if(!logged)
            navigate('/admin/login')
    }, [logged])
    return (
        <>
            {
                logged?
                    allowedRoles.find(element => user.roles.findIndex(role => role.nombre === element) !== -1)?
                        children
                    :
                        <Navigate to={'/admin/login'}/>
                :
                    <Navigate to={'/admin/login'}/>
            }
        </>
    )
}

export default ProtectedPath
