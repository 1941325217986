import styled from 'styled-components'

import {
    RiHeartAddLine
} from 'react-icons/ri'
import {
    BiImageAdd
} from 'react-icons/bi'


export const AddDonation = styled(RiHeartAddLine)`
  font-size: 55px;
  background-color: #4BAA31;
  border-radius: 50%;
  padding: 5px;
  color: white;
  position: fixed;
  z-index: 3;
  bottom: 150px;
  right: 20px;
  margin: 5px;
  cursor: pointer;
  box-shadow: 3px 1px 8px 1px rgba(68, 68, 68, 0.83);
  :hover{
    .tooltip{
      visibility: visible;
    }
    
  }
  
`
export const ManageCarrousel = styled(BiImageAdd)`
  font-size: 55px;
  background-color: #4BAA31;
  border-radius: 50%;
  padding: 5px;
  color: white;
  position: fixed;
  z-index: 3;
  bottom: 80px;
  right: 20px;
  margin: 5px;
  cursor: pointer;
  box-shadow: 3px 1px 8px 1px rgba(68, 68, 68, 0.83); 
`

export const WrapperCMS = styled.div`
    width: 100%;
    position: relative;
    overflow: auto; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    font-size: 18px;
    transition: all 500ms ease;
 

    h1{
        font-size: 2rem;
        margin-top: 16px;
        padding: 10px 21px;
        background-color: white;
        border-radius: 15px;
        box-shadow: 1px 1px 3px 2px #77777754;
    }

    .loader{
        margin-top: 150px;
    }
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
    :hover{
      visibility: visible;
    }
  }
  
  
`

