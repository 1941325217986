import styled from 'styled-components'

export const CarrouselWrapper = styled.div`
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;


    @media screen and (max-width: 1020px){
        height: calc(60vh - 40px);
    }

    [name='carrousel-img']{
        width: 100%;
        transition: 3s;
        position: absolute;
        object-fit: contain;
    }
    img{ 
      display: block;
    }

    .notInImg{
        display: none;
    }

    .presentImg{
        left: 0;
        visibility: visible; 
    }

    .previousImg{
        left: -100vw;
        visibility: hidden; 
    }

    .nextImg{
        left: 100vw;
        visibility: hidden; 
    }

    .previous, .next{
        position: absolute;
        top: calc(50% - 50px);
        margin: 0 50px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-top: solid 5px #4BAA31;
        border-right: solid 5px #4BAA31;
        height: 30px;
        width: 30px;
        opacity: 0.75;

        @media screen and (max-width: 1020px){
            top: calc(30vh - 64px);
            height: 20px;
            width: 20px;
            margin: 0 25px;
        }
    }


    .previous{
        left: 0;
        transform: rotate(-135deg);
    }

    .next{
        right: 0;
        transform: rotate(45deg);
    }


    .action{
        position: absolute;
        bottom: 10px;
        right: 0px;
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
`

export const ButtonWrapper = styled.div`
    position: absolute;

    bottom: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    button{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 2px white;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        opacity: 0.75;
    }

    .selected{
        background-color: white;
    }
`
export const SliderWrapper = styled.div`

    display: flex;
    gap: 5px;
    align-items: center;
    margin: 0 auto;
    justify-content:space-between ;

    @media screen and (max-width: 320px){
            flex-direction:column
        }

    .previous, .next{

    top: calc(50% - 50px);
        margin: 0 50px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-top: solid 5px #4BAA31;
        border-right: solid 5px #4BAA31;
        height: 30px;
        width: 30px;
        opacity: 0.75;

        @media screen and (max-width: 1020px){
            top: calc(30vh - 64px);
            height: 20px;
            width: 20px;
            margin: 0 25px;
        }
    }


    .previous{
        left: 0;
        transform: rotate(-135deg);
    }

    .next{
        right: 0;
        transform: rotate(45deg);
    }

    .img_thumb{
        border: 2px solid black;
        padding: 0px;
        margin: 0px;
        max-width: 100%;
        max-height: none;
        width: 116px;
        height: 100px;
        object-fit: cover;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    gap: 10px;
`