import styled, { css } from 'styled-components'

const ContainerNoText = css`
  display: flex; 
  flex-direction: row-reverse; 
  justify-content: space-between;
`

const ImageNoText = css`
  float: right;
  shape-outside: circle(100% at 100%  15%) border-box;
  shape-margin: 5px;
  margin-left: 5px;

  @media(max-width: 760px){
    height: 244px;
    width: 155px;
    shape-outside: circle(140% at 177% 36%) border-box
  }

  @media(max-width: 320px){
      height: 225px;
      width: 115px;
    }
`

export const StyledHeartContainer = styled.div`
  width: 90%;
  margin: 25px 0;
  color: white;
  height: min-content;
  background-color: #40a52b;
  overflow: hidden;
  padding: 15px;
  border-radius: 15px;

  @media(max-width: 932px){
    margin: auto;
    height:100%;
    overflow-y:scroll;
    }

    

  ${ ({ noText }) => noText ? ContainerNoText : '' }

  img{
    height: 450px;
    width: 305px;
    margin: -25px;

    @media(max-width: 760px){
      height: 650px;
      width: 250px;
    }

    @media(max-width: 550px){
      height: 200px;
    width: 100px;
    }

   

    ${ ({ noText }) => !noText ? ImageNoText : '' }
  }

  ${({childStyle}) => {return(childStyle)}}
 `

