import styled from "styled-components";
import {FaSpinner} from "react-icons/fa";

export const LoadingText = styled.div`
    font-size: 14px;
`

export const Spin = styled(FaSpinner)`
    font-size: ${ props => props.iconSize ? props.iconSize : '32px'};
    color: ${ props => props.color ? props.color : "#3c8dbc"}; 
    animation: spin infinite 1s linear;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    
`

export const LoadingWrapper = styled.div`
    display: grid;
    place-items: center;
    justify-content: center;
    align-items: center;
`
