import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: teaming;
    src: url("${process.env.PUBLIC_URL}/fonts/segoepr.ttf");
  }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      //font-family: "Segoe UI Symbol" !important;
      //font-family:  'Roboto','Helvetica Neue',Helvetica,Arial,sans-serif;
    }

    #root{
        background-color: white;
        background-image: url("${process.env.PUBLIC_URL}/imagenes/__6_Fondo_blanco.png");
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        position: relative; 
        justify-content: space-between;
        overflow-x: hidden;
    }
    .title{
      color: white;
    }
    body{
        background-color: #ecf0f5;
    }
`

export default GlobalStyle;
