import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthService from '../Services/AuthService'

const UserContext = createContext([])

export const useUserContext = () => useContext(UserContext)

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(window.localStorage.getItem('user-teaming') ? JSON.parse(window.localStorage.getItem('user-teaming')) : null);
    const [logged, setLogged] = useState(user ? true : false);
    const [triedLog, setTriedLog] = useState(false);
    const navigate = useNavigate()

    const handleUser = e => {
        let userRef = {
            ...user,
            [e.target.name]: e.target.value
        }
        setUser(userRef)
    }

    const logOut = () => {
        if(window.localStorage.getItem('user-teaming'))
            window.localStorage.removeItem('user-teaming')

        setUser(null)
        setLogged(false)
        setTriedLog(false)
    }

    function LoginCallBack(json ){
        if(json.status === 'SUCCESS')
        {
            let userRef = {
                ...json.data,
            }
            setLogged(true)
            setUser(userRef)
        }
        else
        {
            setTriedLog(true)
        }
    }
    const logIn = async (e) => {
        e.preventDefault()
        await AuthService.login(user,LoginCallBack)
    }

    useEffect(() => {
        if(user && logged){
            delete user.password;
            window.localStorage.setItem('user-teaming', JSON.stringify(user))
        }
    }, [user, logged])

    return (
        <UserContext.Provider value={{
            user,
            logged,
            triedLog,
            handleUser,
            logIn,
            logOut,
            setTriedLog,
        }}>
            { children }
        </UserContext.Provider>
    )
}
