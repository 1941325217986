//Styled Components
import { HelpContainer } from './elements'
import { Link } from 'react-router-dom'

//Components
import DonacionTarjeta from '../../components/DonacionTarjeta'
const Help = () => {

  return (
    <HelpContainer>
        <h1>Ayuda</h1>
        <p>Listado de pasos:</p>
        <p>Para acceder al App Teaming hacé clic en el ícono con nombre Teaming que está ubicado dentro de la Fan page Sumate a CAT Technologies.</p>
        <p>Si querés saber de la iniciativa ingresá a la sección <Link to={'/about'}><strong>“ACERCA DE TEAMING”</strong></Link> donde encontrarás ¿Qué es Teaming? ¿En qué consiste? y ¿Cómo funciona?</p>
        <p>Para ver nuestras campañas con la información de cada institución ingresá a la sección <Link to={'/DonationsPage'}><strong>“NUESTRAS DONACIONES”</strong></Link> y luego accedé al link de cada institución donde encontrarás una breve reseña, fecha que entregamos la donación, el monto por el que se realizó la donación, las imágenes tomadas y el video editado con subtítulos y fotos de nuestra visita.</p>
        <p>Para sugerir una institución, accedé a la sección <Link to={'/sugeri'}><strong>“SUGERÍ UNA INSTITUCIÓN”</strong></Link> y completá el formulario y sugerencia, luego hacé clic en enviar.</p>
        <p>Si sos empleado de CAT Technologies, y si querés adherirte a las prácticas de Teaming y comenzar a donar un monto determinado todos los meses, ingresá a la sección <Link to={'/joinUs'}><strong>¡SUMATE!</strong></Link> para dejarnos en un formulario tu consulta e información así te contactamos. Accedé a esa sección, colocando tu número de documento y luego te direcciona a un formulario, completalo con tus datos, tu consulta y hacé clic en enviar.</p>
    </HelpContainer>
  )
}

export default Help
