//React
import {
    useState,
    useEffect,useContext
} from 'react'
import { Route, Routes, useParams, useNavigate } from "react-router-dom"

import { RMIUploader } from "react-multiple-image-uploader";
//Styled components
import {
    WrapperManager,
    FormWrapper,
    FileWrapper,
} from './elements'

//Components
import Loading from '../../components/Loading'

//Context
import DonationsContext from '../../contexts/DonationsContext'
import CarrouselService from '../../Services/CarrouselService'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationPopUp, {ShowMessagePopUp} from "../../components/ConfirmationPopUp";
import CarrouselUpload from "../../components/CarrouselUpload";
import Button from "../../components/Button";
import {useUserContext} from "../../contexts/UserContext";
registerLocale(es);


const CarrouselManager = () => {
    const {  deleteImage  } = useContext(DonationsContext)
    const {  user  } = useUserContext();
    const [images,setImages] = useState([]);
    const [loading,setLoading] = useState(true);
    const [loadingDelet,setLoadingDelet] = useState(false);
    const [error,setError] = useState({error:false});
    const [exito,setExito] = useState({status:false});
    const [deleting,setDeleting] = useState({status:false});

    const navigate = useNavigate()
    useEffect(()=>{
        setLoading(true);
        CarrouselService.getAll(true,(result)=>{

            setImages(result);
            setLoading(false);
        })
    },[])
    const closePopUp = () => {
        setError({error:false});
    };
    const onUpload = (files) => {
        let x =[...images];

        for(let i = 0; i < files.length; i++){
            if(x.find(image =>image.file? image.file.name===files[i].name:false)===undefined)
                x.push({tipo:'imagen', file:files[i], posicion:x.length, active:true})
        }
        setImages(x);
    };
    const onSelect = (index,newIndex) => {
        let x =[...images];
        let aux = x[index];
        let aux2 = x[newIndex];
        aux.posicion=newIndex;
        aux2.posicion=index;
        x[index] = aux2;
        x[newIndex] = aux;
        setImages(x);
    };
    const onRemove = (index) => {
        setDeleting({status:true,id:index});
    };
    const remove = (index) => {
        setLoadingDelet(true)
        let x =[...images];
        var fileRemoved = x.splice(index,1)[0];
        if(fileRemoved.id !== undefined){
            CarrouselService.deleteImage(fileRemoved.id,user.token,(res)=>{
                res && !res.message? setExito({status:true,message:'Se elimino la imagen con exito.'}):setError({error:true,message:'Ocurrió un error al eliminar la imagen.'});
                setLoadingDelet(false);
                setImages(x)
            })
        }else{
            setLoadingDelet(false);
            setImages(x)
        }
    };
    const Guardar =  () => {
        let res = true;
        setLoading(true);
        CarrouselService.create(images,user.token,(res)=>{
            res === null? setError({error:true,message:'Ocurrió un error al guardar las modificaciones.'}):setExito({status:true,message:'Se guardo el estado correctamente.'});
            setLoading(false);
        })
    };

    return (
        <WrapperManager>
            <ConfirmationPopUp show={deleting.status} title={'Eliminar Imagen'} content={'Esta seguro que desea eliminar esta donación'} closeHander={() => setDeleting({status:false,id:null})}>
                <Button id={deleting.id} size={'small'} color={'green'} hover={'#5ac079'} onClickHandle={()=>remove(deleting.id)}>
                    {loadingDelet? <Loading/>:'Confirmar'}
                </Button>
                <Button size={'small'} color={'white'} hover={'#b30000'} onClickHandle={() => setDeleting({status:false,id:null})}>
                    Cancelar
                </Button>
            </ConfirmationPopUp>
            <ShowMessagePopUp tipo={"error"} show={error.error} closeHander={closePopUp} title={'¡Error!'} content={error.message}/>
            <ShowMessagePopUp  show={exito.status} closeHander={()=>{setExito({status:false})}} title={'¡Exito!'} content={exito.message}/>

            <h1>Nueva Imagen</h1>
            {
                loading ?
                    <Loading text={"Aguardé unos segundos por favor..."}/>
                :
                    <>
                        <FormWrapper>
                            <FileWrapper>
                                <CarrouselUpload accept={"image/*"} multiple={'multiple'} name={'imagen'} files={images} updateFilesCb={onUpload} changeState={onSelect} removeFile={onRemove} label={'Imagenes'} accept="image/png, image/gif, image/jpeg"/>
                            </FileWrapper>
                            <Button className={'submit-btn'} id={deleting.id} size={'small'} color={'#6CC788'} hover={'#5ac079'} onClickHandle={()=>Guardar( )}>
                                Guardar
                            </Button>
                        </FormWrapper>
                    </>
            }
        </WrapperManager>
    )
}

export default CarrouselManager
