//Styled Components
import {
    StyledButton
} from './elements'

const Button = ({disabled=false, children, name, className, text, align, size, color, hover, onClickHandle,id }) => {

    return (
        <StyledButton disabled={disabled} name={name} id={id} className={className} size={size} color={color} hover={hover} align={align}
                      onClick={(e)=>{onClickHandle(e)}}>
            {children}
            { text }
        </StyledButton>
    )
}

export default Button
