import styled from 'styled-components'
import { NavLink as Link, Link as LinkRouter} from 'react-router-dom'
import {BiExit} from "react-icons/bi";

export const SidebarLinkFake=  styled.a`
  
    height: 50px; 
    padding: 8px 25px;
    color:#45a731;
    background-color:white;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 10px; 
    margin: 5px 1rem 5px 5px;
    align-items: center;
    width: 80%;
  &:hover{
    background-color: #606060;
    color:white;
  }
  @media(min-width: 1221px){
    width: auto;
  }
  @media(max-width: 700px){
    padding: 8px 0px;
    font-size: 20px;
  }
`
export const SidebarLink=  styled(LinkRouter)`
    padding: 8px 25px;
    color:#45a731;
    text-decoration: none; 
    background-color:white;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover{
        background-color: #606060;
        color:white;
    }
    font-weight: 600;
    border-radius: 10px;
    margin: 5px 1rem 5px 5px;
    display: flex;
    align-items: center;
    min-width: 70px;
    gap: 3px;
    width: 85%;
    @media(max-width: 700px){
      padding: 8px 0px;
      font-size: 20px; 
    }
`
export const IconLogOut = styled(BiExit)`
`
export const Wrapper = styled.nav` 
  display: flex;
  justify-content: space-between;
  background-color: #4BAA31;
  .selected{
    background-color: #606060;
    color:white;
  }
`
export const NavContainer = styled.nav`
  padding: .4rem; 
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 80px;
  h2{
    color: white;
    font-weight: 400;
    span{
      font-weight: bold;
    }
  }

  .links{
    display: flex;
    position: absolute;
    top: -700px;
    left: -2000px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    transition: all .5s ease;
    a { 
      display: flex;
      width: fit-content; 
      align-items: center;
      justify-content: center;
      font-size: 1.4rem; 
      width: 80%;
    }
    @media(min-width: 1221px){
      position: initial;
      margin: 0;
      
      a {
        font-size: 1rem;
        display: flex;
      }
      display: flex;
    }
  }
  .links.active{ 
    display: block;
    position: absolute;
    margin-left: 15%;
    margin-right: 15%;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 100;
    a{
      font-size: 1.4rem;
      margin-top: 1rem;
      color:#45a731;
      @media (max-width: 700px) {
        font-size: 1.2em; 
      }
    }
  }
  .burguer{
    z-index: 100;
    @media(min-width: 1221px){
      display: none;
    }
    @media(max-width: 490px){
      .nav-icon-5{
       height: 22px;
       width: 25px;
         span:nth-child(2){
          top:9px;
        }
     }
    }
  }
`

export const BgDiv = styled.div`
  background-color: #4BAA31;
  position: absolute;
  top: -100vh;
  left: -100vw;
  width: 100%;
  height: 100%;
  z-index: 90;
  transition: all .6s ease ;
  
  &.active{
    border-radius: 0 0 80% 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const NavLogo = styled(Link)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    z-index: 100;

    img{
        height: 100px;
        margin-top: -6px;

        @media(max-width: 490px){
          height: 60px;
        }
    }
    #cat-logo{
      height: 80px;
      margin-top: -14px;
      @media(max-width: 490px){
        height: 50px;
        margin-top: -20px;
      }
    }
`
