//Styled Components
import {
  StyledSection,
} from './elements'



const Section = ({  children }) => {
  return (
    <StyledSection>
      {children}
    </StyledSection>
  )
}

export default Section
