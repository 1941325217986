import styled from 'styled-components'
import { Link } from 'react-router-dom'
export const CarrouselWrapper = styled.div`
    width: 100%;
    height: 75vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;


    @media screen and (max-width: 1020px){
        height: calc(60vh - 40px);
    }

    [name='carrousel-img']{
        width: 100%;
        transition: 1s;
        position: absolute;
        object-fit: scale-down;
    }
    img{ 
      display: block;
    }

    .banner_link{
        width: 100%;
        transition: 1s;
        position: absolute;
        display: block;
        object-fit: contain;

        @media screen and (max-width: 760px){
            object-fit: fill;
        }
    }

    .notInImg{
        display: none;
    }

    .presentImg{
        left: 0;
        visibility: visible; 
    }

    .previousImg{
        left: -100vw;
        visibility: hidden; 
    }

    .nextImg{
        left: 100vw;
        visibility: hidden; 
    }

    .previous, .next{
        position: absolute;
        top: calc(50% - 50px);
        margin: 0 50px;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border-top: solid 5px #4BAA31;
        border-right: solid 5px #4BAA31;
        height: 30px;
        width: 30px;
        opacity: 0.75;

        @media screen and (max-width: 1020px){
            top: calc(30vh - 64px);
            height: 20px;
            width: 20px;
            margin: 0 25px;
        }
    }

    .previous{
        left: 0;
        transform: rotate(-135deg);
    }

    .next{
        right: 0;
        transform: rotate(45deg);
    }
`

export const ButtonWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    button{
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: solid 2px white;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        opacity: 0.75;
    }

    .selected{
        background-color: white;
    }
`
export const StyledLink = styled(Link)`
    padding: 5px 5px 5px 25px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    color: #8aa4af;
    font-size: 14px;
    text-decoration: none;

    &:hover{
        color: white;
    }
`

export const BannerLinkWrapper = styled.div `
    display: flex;
    height: 50vh;
    @media screen and (max-width: 760px){
        height: 22vh;
        }
    
`