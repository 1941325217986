//React
import {
    useState,
    useEffect,useContext
} from 'react'
import { Route, Routes, useParams, useNavigate } from "react-router-dom"

import { RMIUploader } from "react-multiple-image-uploader";
//Styled components
import {
    WrapperCMS,
    WrapperDonacion,
    WrapperPreview,
    Row,
    FormWrapper,
    Select,
    DonacionWrapper,
    FileWrapper,
    Textarea,
    Volver,
    Close,
    FixedWrapper
} from './elements'

//Components
import Input from '../../components/Input'
import DonationCard from '../../components/DonationCard'
import FileUpload from '../../components/FileUpload'
import MultiFileUpload from '../../components/MultiFileUpload'
import Button from '../../components/Button'
import Loading from '../../components/Loading'

//Context
import DonationsContext from '../../contexts/DonationsContext'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/es"; // the locale you want
import "react-datepicker/dist/react-datepicker.css";
import {ShowMessagePopUp} from "../../components/ConfirmationPopUp";
registerLocale(es);


const AltaDonacion = () => {
    const { nuevaDonation,error,exito,setError,setExito, setNuevaDonation,validateDonation, changeHandler,deleteImage,deleteVideo, createDonation, editDonation, donationsCreating,setNuevaDonationById,donationsLoading } = useContext(DonationsContext)
    const { id } = useParams()
    const navigate = useNavigate()
    useEffect(()=>{
        if(id !== undefined){
            setNuevaDonationById(id);
        }
    },[])
    const closePopUp = () => {
        setError({error:false});
    };
    const videoHandler = (newDon) => {
        if(newDon.id !== undefined && newDon.video === 'no'){
            deleteVideo(newDon.id);
        }
        setNuevaDonation(newDon);
    };
    const onUpload = (files) => {
        let x =[...nuevaDonation.imagenes];

        for(let i = 0; i < files.length; i++){
            if(x.find(image =>image.file? image.file.name===files[i].name:false)===undefined)
                x.push({tipo:'multimedia',file:files[i]})
        }
        changeHandler(x,'imagenes')
    };
    const onSelect = (index,tipoActual) => {
        let x =[...nuevaDonation.imagenes];
        if( x[index].tipo !== tipoActual)
            return
        if(tipoActual==='miniatura')
            x[index].tipo = 'multimedia';
        if(tipoActual==='multimedia')
            x[index].tipo = 'miniatura';
        changeHandler(x,'imagenes')

    };
    const onRemove = (index) => {
        let x =[...nuevaDonation.imagenes];
        var fileRemoved = x.splice(index,1)[0];
        if(fileRemoved.id !== undefined){
            deleteImage(fileRemoved.id)
        }
        changeHandler(x,'imagenes')
    };
    const submit =  () => {
        let res =validateDonation();

        if(res!==true){
            setError({error:true,message:res})
            return;
        }
        id ? editDonation() : createDonation()
    };

    return (
        <WrapperDonacion>
            <ShowMessagePopUp tipo={"error"} show={error.error} closeHander={closePopUp} title={'¡Error!'} content={error.message}/>
            <ShowMessagePopUp  show={exito.status} closeHander={()=>{setExito({status:false})}} title={'¡Exito!'} content={exito.message}/>

            <h1>Nueva Donación</h1>
            {
                donationsCreating || donationsLoading?
                    <Loading text={"Aguardé unos segundos por favor..."}/>
                :
                    <>
                        <FormWrapper>
                            <Input idValue={"title"} values={nuevaDonation} changeValues={setNuevaDonation} text={"Título"}/>
                            <Input idValue={"institucion"} values={nuevaDonation} changeValues={setNuevaDonation} text={"Institución"}/>
                            <Input onlyNumbers={true} idValue={"amount"} values={nuevaDonation} changeValues={setNuevaDonation} text={"Monto de la donación"}/>
                            <Textarea rows = "3" cols = "40" maxlength = "100">
                                Resumen
                                <textarea value={nuevaDonation && nuevaDonation.resumen ? nuevaDonation.resumen : ''} onChange={e => changeHandler(e.target.value, 'resumen')}></textarea>
                            </Textarea>
                            <Textarea>
                                Texto
                                <textarea value={nuevaDonation && nuevaDonation.text ? nuevaDonation.text : ''} onChange={e => changeHandler(e.target.value, 'text')}></textarea>
                            </Textarea>
                            <Row>
                                <Select>
                                    Ubicación
                                    <select name={'location'} onChange={(e) => changeHandler(e.target.value, 'location')} defaultValue={nuevaDonation && nuevaDonation.location ? nuevaDonation.location : ''}>
                                        <option value={'Buenos Aires'}>Buenos Aires</option>
                                        <option value={'San Luis'}>San Luis</option>
                                    </select>
                                </Select>
                                <Select>
                                    Fecha
                                    <DatePicker
                                        selected={nuevaDonation.date}
                                        onChange={(date)=>{changeHandler(date,"date")}}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </Select>
                            </Row>
                            <Input type='radio' idValue={"video"} values={nuevaDonation} changeValues={videoHandler} text={"Video"} options={['Si', 'No']}/>
                            {
                                nuevaDonation && nuevaDonation.video === 'Si'?
                                    <Input idValue={"link_video"} values={nuevaDonation} changeValues={setNuevaDonation} placeholder={"Link"}/>
                                :
                                    ''
                            }
                            <FileWrapper>
                                <MultiFileUpload accept={"image/*"} multiple={'multiple'} name={'imagen'} files={nuevaDonation.imagenes} updateFilesCb={onUpload} changeState={onSelect} removeFile={onRemove} label={'Imagenes'} accept="image/png, image/gif, image/jpeg"/>
                            </FileWrapper>
                            <DonacionWrapper>
                                <DonationCard donation={nuevaDonation} preview={true}/>
                            </DonacionWrapper>
                        </FormWrapper>
                        <Button  text={'Guardar'} onClickHandle={submit }></Button>
                    </>
            }
        </WrapperDonacion>
    )
}

export default AltaDonacion
