export const FormComponents = [
    {
        input: 'text',
        title: 'Nombre',
        id: 'name',
        obligatory: true,
    },
    {
        input: 'text',
        title: 'Apellido',
        id: 'lastName',
        obligatory: true,
    },
    {
        input: 'text',
        title: 'E-mail',
        id: 'email',
        obligatory: true,
    },
    {
        input: 'text',
        title: 'Institución',
        id: 'institucion',
        obligatory: true,

    },
    {
        input:'textarea',
        title:'Comentarios',
        id:'comment'
    },
    {
        input:'checkbox',
        title:'Acepto los términos y condiciones de uso',
        id:'terms',
        obligatory: true,
    },
]

export const required = [
    'name','lastName','email','institucion', 'terms'
]
