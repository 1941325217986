//React
import {useEffect, useState} from "react"
import {useNavigate} from 'react-router-dom'

//Styled Components
import {
    TarjetaWrapper, ImageSection, TextSection,LoadingWrapper
} from './elements'

//Components
import Loading from "../Loading";
import {API_URL} from "../../Settings/ApiSettings";
import Button from "../Button";

//Data aux
const data = {
    imagenes:[{url:process.env.PUBLIC_URL+'/imagenes/close-up-volunteer-and-food-in-box.jpg'}],
    tittle:"Ultima donación",
    institucion:"Comedor XXXXX",
    resumen:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet convallis bibendum. Maecenas auctor mauris quam, non rutrum purus sagittis in. Quisque pellentesque ex arcu, ac pretium nisl tincidunt ac. Aenean sit amet pellentesque dui. Vivamus condimentum lacus lobortis, porttitor ipsum sed, pharetra eros. Cras volutpat euismod leo vulputate condimentum. Phasellus pretium ante sit amet dui rutrum, vel hendrerit nisl semper. Suspendisse ante sem, congue et condimentum et, vestibulum sed neque.",

}

const DonacionTarjeta = ({name='', className }) => {
    const [dataDonation, setDataDonation] = useState({});
    const [loading,setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() =>{ //Consulta a la base de datos por la ultima donacion
        setLoading(true);
        //setTimeout(() => {
        //    setDataDonation(data);
        //    setLoading(false);
        //}, 1000);
        fetch(`${API_URL}/donaciones/lastOne`, {
            method: 'GET'
        })
            .then((response) => response.json())
            .then( (result) => {
                    
                    if(result.status === 'SUCCESS')
                        setDataDonation(result.data)
                    setLoading(false);
            })
            .catch((error)=> console.log('Algo fallo en la conexion a la base de datos: '+ error.message))
    },[])

    return (
        <>
            {
                loading?
                    <LoadingWrapper>
                        <Loading iconSize={"60px"}/>
                    </LoadingWrapper>
                    :
                    <TarjetaWrapper   className={className}>
                        <ImageSection src={'https://teaming.cat-technologies.com:8000'+dataDonation.imagenes[0].url}>
                            <div className={'banner'} >
                                <img src={'https://teaming.cat-technologies.com:8000'+dataDonation.imagenes[0].url} alt='imagen'/>
                            </div>
                        </ImageSection>
                        <TextSection>
                            <h2>{dataDonation.title}</h2>
                            <p className="teaming">{dataDonation.institucion}</p>
                            <p>{dataDonation.resumen}</p>
                            <Button size={'small'} color={"white"} onClickHandle={() => {navigate(`/Donation/${dataDonation.id}`)}}>
                                VER MÁS
                            </Button>
                        </TextSection>
                    </TarjetaWrapper>
            }
        </>
    )
}

export default DonacionTarjeta
