//React
import {
    useContext
} from 'react'
import { Route, Routes, useNavigate } from "react-router-dom"

//Styled components
import {
    WrapperCMS,
    AddDonation,ManageCarrousel,Tooltip
} from './elements'

//Components
import DonationsPage from '../DonationsPage'

//Context
import DonationsContext, {DonationContextProvider} from '../../contexts/DonationsContext'

import AltaDonacion from "../AltaDonacion";
import ProtectedPath from "../../components/ProtectedPath";
import CarrouselManager from "../CarrouselManager";
const calculateModel = (model) => {
    if(model === 1)
        return 'small'
    if(model === 2)
        return 'medium-vertical'
    if(model === 3)
        return 'medium-horizontal'
    if(model === 4)
        return 'large'
}


const CMS = () => {
    const { donationsLoading, donationsDeleting } = useContext(DonationsContext)
    const navigate = useNavigate()
    return(
    <DonationContextProvider>
        <Routes>
                <Route
                    index
                    element={
                        <ProtectedPath allowedRoles={['administrador']}>
                            <WrapperCMS>
                                <>
                                    <h1>Grilla de Donaciones</h1>
                                    {
                                        donationsDeleting || donationsLoading?
                                            ''
                                        :
                                            <>
                                                <AddDonation onClick={(e)=>navigate('/admin/cms/donacion')}/>
                                                <ManageCarrousel onClick={(e)=>navigate('/admin/cms/carrousel')}/>

                                                <DonationsPage adminMode={true}/>
                                            </>
                                    }
                                </>
                            </WrapperCMS>
                        </ProtectedPath>
                    }
                />
                <Route path="donacion">
                    <Route index element={<ProtectedPath  allowedRoles={['administrador']}><AltaDonacion/> </ProtectedPath>} />
                    <Route path=":id"  element={<ProtectedPath  allowedRoles={['administrador']}><AltaDonacion/></ProtectedPath>} />
                </Route>
                <Route path="carrousel">
                    <Route index element={<ProtectedPath  allowedRoles={['administrador']}><CarrouselManager/> </ProtectedPath>} />
                </Route>

            </Routes>
    </DonationContextProvider>
    )
}

export default CMS
