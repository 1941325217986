import styled from "styled-components"
import { FaUserAlt, FaLock } from 'react-icons/fa'

export const LoginPage = styled.div`
  display: grid;
  place-items: center;
`

export const LoginWrapper = styled.div`
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-shadow: 0px 0px 5px 4px rgb(0 0 0 / 30%);
  color: white;
  background-color: #40a52b;
  border-radius: 8px;
`


export const LogoWrapper = styled.div`
  padding: 15px;
  height: 35%;
  display: flex;
  
`
export const LoadingWrapper = styled.div`
  //background: #fff; 
  align-items: center;
  justify-content: center; 
  border-radius: 20px;
  svg {
    color: white;
  }
  //box-shadow: 1px 1px 6px 1px #4f4f4f;
`

export const FormWrapper = styled.form`
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap:60px;
  position: relative;

  & p{
    color: #666;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  & span{
    color: red;
    margin: 15px 0;
    position: absolute;
    top: 130px;
  }
`

export const Input = styled.div`
  width: 100%;
  border: solid 1px #ccc;
  margin-bottom: 10px;
  position: relative;
  animation: zoom-in 2s ease;

  @keyframes zoom-in {
    0% {
      transform: scale(0, 0);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  & input{
    color: black;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 45px 10px 10px;
  }
`

export const IconUser = styled(FaUserAlt)`
  position: absolute;
  right: 10px;
  top: calc(50% - 7px);
  color: #666;
  font-size: 14px;
`

export const IconPassword = styled(FaLock)`
  position: absolute;
  right: 10px;
  top: calc(50% - 7px);
  color: #666;
  font-size: 14px;
`

export const SubmitWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;

  &.end{
    justify-content: flex-end;
  }

  &.center{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & button{
    z-index:2;
  }
`
