import {  useRef } from "react"
import {
    FileUploadWrapper,
    FormField,
    InputLabel,
    WraperList,
    UploadFileBtn,
    UploadIcon,
    Card,
    TrashFiles
} from './elements'
import {url} from '../../Services/constantes'
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 600000;
const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const MultiFileUpload = ({
        files,
        setFiles,
        label,
        updateFilesCb,removeFile,changeState,
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        ...otherProps
    }) => {
    const fileInputField = useRef(null);
    const rmFile = (index) => {
        removeFile(index);
    }

    const handleUploadBtnClick = e => {
        fileInputField.current.click();
    };

    const handleNewFileUpload = (e) => {
        const file = e.target.files;
        if (file.length) {
            updateFilesCb(e.target.files);
        }
        e.target.value = '';
    }

    function dragOverHandler(ev) {
        ev.preventDefault();
    }

    return (
        <>
            <FileUploadWrapper onDragOver={dragOverHandler}>
                <InputLabel>{label}</InputLabel>
                <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                    <UploadIcon/>
                    <span>Suba un archivo</span>
                </UploadFileBtn>
                <FormField
                    type="file"
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=""
                    value=""
                    {...otherProps}
                />
            </FileUploadWrapper>

            {
                files.length === 0
                    ?
                    <WraperList>

                    </WraperList>
                    :
                    <WraperList>
                        {
                            files.map((image,index) =>{

                                if (image.tipo === 'video'){
                                    return(
                                        <Card   key={index}>
                                            <label  className={image.tipo}>{"video"}</label>
                                            <iframe src={image.url}></iframe>
                                        </Card>
                                    )
                                }
                                return(
                                    <Card   key={index}>
                                        <label className={image.tipo}>{image.tipo}</label>
                                        <TrashFiles onClick={(e)=>rmFile(index)}/>
                                        <img onClick={(e)=>{changeState(index,image.tipo)}} height={'150px'}width={'150px'}
                                             src={image.url !== undefined? `${url.slice(0,-4)}${image.url}`:URL.createObjectURL(image.file)}
                                             alt={image.url !== undefined? `${image.url.split('/')[-1]}`:image.file.name}/>
                                    </Card>
                                )
                            })
                        }
                    </WraperList>
            }
        </>
    )
}

export default MultiFileUpload;
