import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column; 
  height: 100%; 
  color: white; 
  width: calc(100% - 270px);
  padding: 20px 5px 32px 30px;
  gap: 10px;

  @media(max-width: 760px){
    width: calc(100% - 70px);
    padding: 20px 5px 32px 10px;
  }
`

export const Wrapper = styled.div`   
  height: min-content; 
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  transition: all 500ms ease;
`

export const ButtonWrapper = styled.div`
   display: flex;
   margin-bottom: 25px;
`;
